@import url(./variable.less);

.ant-layout-content,
.container,
.custom-label,
.workspace-agency-details,
.workspace-details-form,
.sync-container .btn-component,
.btn-component {
  .solid {
    .d-flex;
    .flex-horizontal;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    height: 38px;
    gap: 6px;
    background: @grey-07;
    min-width: 80px;
    border-radius: 6px;
    color: @product-status-white;
    box-shadow: none;
    border-color: transparent;
    font: @body-small-med;
    &:hover {
      color: @product-status-white;
      border-color: @product-status-white;
    }
    &:disabled {
      background-color: @grey-btn;
      color: @product-status-white;
    }
  }
  .outlined {
    .d-flex;
    .flex-horizontal;
    align-items: center;
    padding: 8px 12px;
    height: 38px;
    gap: 6px;
    justify-content: center;
    min-width: 80px;
    background: transparent;
    border-radius: 6px;
    color: @grey-07;
    box-shadow: none;
    border: 1px @grey-07 solid;
    font: @body-small-med;
    &:hover {
      color: @grey-07;
      border-color: @grey-07;
    }
    &:disabled {
      background-color: @grey-btn;
      color: @grey-07;
    }
  }
  .primary-outlined {
    .d-flex;
    .flex-horizontal;
    align-items: center;
    padding: 8px 12px;
    height: 38px;
    gap: 6px;
    background: transparent;
    border-radius: 6px;
    color: @emerald-main;
    box-shadow: none;
    border: 1px @emerald-main solid;
    font: @body-small-med;
    &:hover {
      color: @emerald-main;
      border-color: @emerald-main;
    }
    &:disabled {
      background-color: @grey-btn;
      color: @product-status-white;
      border: none;
    }
    &.fill {
      background-color: @emerald-main;
      color: @status-white;
    }
  }
  .primary-main-btn {
    width: 100%;
    height: 60px;
    align-items: center;
    background: @emerald-main;
    border-radius: 12px;
    font: @body-large-lar;
    color: @product-status-white;
    &:hover {
      color: @product-status-white;
    }
    &:disabled {
      background-color: @grey-btn;
      color: @product-status-white;
    }
  }
  .primary-main-btn-small {
    .primary-main-btn;
    width: 200px;
  }
  .primary-main-outlined-btn {
    width: 100%;
    height: 60px;
    align-items: center;
    border-radius: 12px;
    font: @body-large-lar;
    background: @emerald-main;
    border-color: @emerald-main;
    color: @product-status-white;
    &:disabled {
      background: @product-status-white;
      color: @emerald-main;
    }
  }
}

.dashed-button-upload {
  .d-flex;
  .align-center;
  .justify-center;
  width: 100%;
  height: 46px;
  align-items: center;
  background: @product-status-white;
  border-radius: 12px;
  font: @body-small-med;
  color: @text-dark;
  border: 1px @stroke-light dashed;
  &:hover {
    color: @text-dark;
    border: 1px @stroke-light dashed;
  }
}

// modalComponent
.modal-components-design {
  top: 4px;
  .ant-modal-close {
    top: 32px;
  }
  .custom-label {
    .ant-form-item {
      margin-bottom: 0;
      &.addressLine2 {
        margin-top: 32px;
      }
    }
    .add-new-project-save-btn {
      margin-top: 24px;
    }
  }
  .ant-modal-content {
    padding: 32px;
    border: 1px solid @stroke-light;
    border-radius: 24px;
    .ant-modal-title {
      .d-flex;
      .align-center;
      column-gap: 4px;
      font: @body-large-lar;
      color: @grey-10;
      margin-bottom: 32px;
    }
  }
  .workspace-agency-details {
    .d-flex;
    .flex-horizontal;
    column-gap: 12px;
    .logo-img {
      width: 100px;
      height: 100px;
      border-radius: 12px;
      object-fit: cover;
    }
  }
  .workspace-agency-logo {
    font: @body-default-reg;
    color: @text-dark;
  }
  .edit-delete-btn {
    margin-top: 6px;
    margin-bottom: 0;
    .d-flex;
    .flex-horizontal;
    gap: 12px;
  }
  .fileAttachment {
    .ant-radio-wrapper {
      .ant-radio + span {
        font: @body-default-med;
        color: @grey-08;
        display: flex;
        align-items: center;
      }
    }
  }
  .view-details-img {
    width: 100px;
    height: 100px;
    border-radius: 12px;
    border: 1px solid @stroke-dark;
  }
}

// cardComponent
.project-card-container {
  margin-top: 31px;
  .ant-card {
    .ant-card-cover {
      & img {
        border-radius: 16px 16px 0 0;
      }
    }
    .ant-card-body {
      height: 100px;
      overflow: hidden;
    }
    .ant-card-actions > li {
      margin: 0;
      margin: 12px 0;
    }
  }
  .project-card {
    min-height: 300px;
    border-radius: 16px;
    background: @status-white;
    overflow: hidden;
    border: 1px solid #d2d2e1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-card-actions {
      background: @background-selected;
      .card-redirect-click {
        display: block;
        width: 100%;
      }
      svg {
        height: 17px;
        width: 16px;
        path {
          stroke: @text-light;
        }
      }
      .dropdown {
        span {
          width: 100%;
          justify-content: center;
        }
      }
    }
    .ant-card-meta-title {
      font: @body-default-lar;
      color: @text-dark;
    }
    .ant-card-meta-description {
      font: @body-small-reg;
      color: @grey-02;
    }
    .card-cover {
      height: 150px;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .card-cover-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      line-height: 28px;
      font-size: 32px;
      font-weight: 500;
      font-family: 'Space Grotesk';
      color: @status-white;
      margin: 0;
    }
    .ant-card-body {
      padding: 0;
      .ant-card-meta {
        padding: 24px;
      }
    }
  }
}

// form-input-box
.custom-label {
  & .lat-lng {
    color: @grey-02;
    font: @body-small-reg;
    margin-top: 8px;
  }
  .ant-input {
    color: @text-light;
    font: @body-default-reg;
  }
  .ant-select-selection-item {
    color: @text-light;
    font-family: Space Grotesk;
    font-size: 16px;
    font-weight: 400;
  }
  .ant-input::placeholder {
    font: @body-default-reg;
    color: @text-dark;
  }
  .ant-select-selection-placeholder {
    font: @body-default-reg;
    color: @text-dark;
  }
  .ant-form-item-label label {
    font: @body-small-reg;
    color: @grey-02;
    flex-direction: row-reverse;
  }
  .ant-form-item-explain-error {
    color: @status-red;
    font: @body-meta-reg;
  }
}

// common-discard-modal custom css
.discard-modal {
  .ant-modal-content {
    padding: 24px;
    border-radius: 16px;
    .ant-modal-header {
      .ant-modal-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
      }
    }
    .ant-modal-body {
      p {
        margin: 16px 0 24px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: @text-light;
      }
    }
    .ant-modal-footer {
      height: 40px;
      .ant-btn {
        min-width: 120px;
        padding: 8px 12px;
        height: inherit;
        box-shadow: none;
        &.ant-btn-default {
          border: 1px @grey-07 solid;
          span {
            color: @grey-07;
            font: @body-default-lar;
          }
        }
        &.ant-btn-primary {
          background-color: @accent-dark;
          span {
            font: @body-default-lar;
          }
        }
      }
    }
  }
}

// common-member-tags custom css
.member-tags {
  min-width: 90px;
  text-align: center;
  padding: 6px 16px;
  border-radius: 20px;
  line-height: 16px;
  &.accepted {
    color: #268033;
    border: 1px solid #89dc95;
  }
  &.invited {
    color: @grey-06;
    border: 1px solid @grey-btn;
  }
  &.cancelled {
    color: @status-red;
    border: 1px solid @status-red;
  }
}

// commonTable custom css
.custom-table {
  .ant-table {
    border-radius: 16px;
    border: 1px @stroke-light solid;
    overflow: hidden;
    .ant-table-container {
      .ant-btn {
        padding: 6px 16px;
        border-radius: 50%;
        font: @body-meta-med;
        font-size: 12px;
      }
      .ant-btn-primary {
        border: 1px solid #89dc95;
        background: #d8f3dc;
        color: #268033;
      }
      .ant-btn-default {
        border: 1px solid @grey-btn;
        background: @grey-01;
        color: @grey-06;
      }
      & table > thead > tr:first-child > *:last-child {
        border-start-end-radius: 16px;
      }
      & table > thead > tr:first-child > *:first-child {
        border-start-start-radius: 16px;
      }
      .ant-table-thead > tr > th {
        background: @product-background-2;
        font: @body-medium-base;
        color: @text-light;
        .ant-table-filter-trigger {
          .anticon-search {
            color: @product-text-dark;
          }
        }
      }
      .ant-table-container > table > thead > tr:first-child > *:first-child {
        border-start-start-radius: 16px;
      }
      .ant-table-tbody {
        font: @body-regular-base;
        color: @product-text-dark;
      }
    }
  }
}
.custom-table-input-search {
  padding: 8px;
  .ant-space {
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
  }
}

.custom-table-analysis {
  .ant-table {
    border-radius: 8px;
    border: 1px @stroke-light solid;
    .ant-table-container {
      & table > thead > tr:first-child > *:last-child {
        border-start-end-radius: 8px;
      }
      & table > thead > tr:first-child > *:first-child {
        border-start-start-radius: 8px;
      }
      .ant-table-content {
        .ant-table-thead > tr > th {
          background: @status-white;
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
          font: @body-bold-lar;
          color: @accent-dark;
        }
      }
    }
  }
}

.detection-slider {
  background-color: @grey-11;
  padding: 28px 22px 16px 22px;
  color: red;
  border-radius: 5px;
  display: flex;
  align-items: center;
  .pause-icon {
    color: @status-black;
  }
  .slider {
    width: 100%;
    margin-left: 16px;
    .ant-slider-rail {
      background-color: @background-2;
      height: 8px;
    }
    .ant-slider-track {
      background-color: @grey-10;
      height: 8px;
    }
    .ant-slider-handle {
      &::after {
        background-color: @grey-10;
        box-shadow: 0 0 0 2px @status-black;
        margin-top: 2px;
      }
    }
  }
}
