@import url(../styles/variable.less);

.content-top {
  .content-top-heading {
    font: @body-large-med;
    color: @grey-10;
  }
  .searchBox .ant-btn-icon-only {
    background: @product-status-white;
    min-width: 40px;
    border-color: @stroke-light;
    .anticon-search {
      color: black;
    }
  }
  .add-on-button {
    justify-content: center;
    gap: 6px;
    text-align: center;
  }
  .project-search-input .ant-input-affix-wrapper {
    border-radius: 6px;
    color: @text-light;
    .ant-input::placeholder {
      color: @text-light;
      font: @body-meta-med;
    }
  }
}

.ant-modal-body {
  & .invite-user-save {
    width: 100%;
    height: 60px;
    font: @body-large-lar;
  }
  .FIE_editor-content {
    position: relative;
    .FIE_watermark-options-wrapper {
      position: absolute;
      top: calc(100% - 128px);
      left: 0px;
      width: calc(100% - 366px);
    }
  }
  .FIE_tools-bar-wrapper {
    .FIE_tools-bar {
      .FIE_watermark-add-wrapper {
        div:nth-child(3) {
          min-width: 300px;
          #myInput {
            // need to write override the package inline css
            padding-right: 35px !important;
          }
        }
      }
    }

    input {
      padding-right: 30px;
    }
  }
}

// container-div or project heading
.inside-project-container {
  .container-div {
    & .ant-typography {
      font: @body-default-med;
      color: @grey-10;
    }
  }
  .project-details {
    & .ant-typography {
      font: @body-small-reg;
      color: @grey-02;
    }
    & p {
      font: @body-default-reg;
      color: @text-dark;
      margin: 0;
      margin-top: 8px;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5; /* Number of lines */
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .project-name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .assign-members {
    background: transparent;
  }
  .videos-list {
    .ant-table-wrapper {
      .ant-table-filter-column {
        justify-content: unset;
      }
      .ant-table-column-title {
        min-width: unset;
        flex: unset;
      }
    }
  }
}

// map model some custom css
.add-new-project-modal {
  .ant-modal-content {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    & .map-img {
      height: 100%;
      & > div {
        border-radius: 24px;
      }
    }
    .ant-modal-header {
      .ant-modal-title {
        margin-bottom: 24px;
      }
    }
  }
  .ant-modal-content .ant-modal-body {
    flex-direction: row;
    align-items: flex-start;

    .project-input-fields {
      .workspace-title {
        margin-bottom: 24px;
        font: @body-large-lar;
        color: @grey-10;
        margin-top: 0;
      }
    }
  }
}

// upload video
.upload-video {
  .ant-upload-select {
    display: block;
  }
  .upload-video-preview {
    column-gap: 12px;
    .upload-video-preview-img {
      width: 100px;
      height: 100px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px @stroke-light solid;
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
    .upload-video-preview-name {
      font: @body-default-reg;
      color: @grey-10;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .upload-video-preview-time {
      font: @body-default-reg;
      color: @product-text-dark;
    }
  }
}

//################################### Details Video #######################################
.entire-synced {
  position: relative;
  top: -40px;
}

//###################################  Remove Member From Workspace remove title css ##################

.remove-member .ant-dropdown-menu-root {
  border-radius: 8px;
  border: 1px solid @stroke-light;
  background: @status-white;
  box-shadow: 0px 1px 3px 0px rgba(81, 79, 85, 0.12);
  font: @body-small-reg;
  .ant-dropdown-menu-item {
    color: @text-light;
  }
}

//  ############################################## custom input for addNewProject ###############

.custom-input-autocomplete {
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: @status-white;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

//  ############################################ Change password button ####################################
.change-pw-title {
  font: @body-default-med;
}
.change-pw {
  font: @body-meta-reg;
  color: #28a864;
  text-decoration-line: underline;
  cursor: pointer;
}
.delete-account-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: underline;
  color: @status-red;
  cursor: pointer;
  font: @body-meta-reg;
}
.google-text {
  font-weight: 600;
}

//######################################### Synchronization #######################################

.title {
  font-size: 20px;
}
.main-wrapper {
  padding: 16px 0;
  column-gap: 32px;
  .terramatic-logo {
    margin-left: -32px;
  }
  .video-player {
    display: flex;
    flex-direction: column;
    .react-player {
      position: relative;
      .seek-play-pause {
        width: 80%;
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        button {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgba(42, 40, 40, 0.3);
          border: none;
          color: white;
          font-size: 17px;
        }
        opacity: 0;
      }

      &:hover {
        .seek-play-pause {
          opacity: 1;
        }
      }
      & video {
        border-radius: 8px;
        border: 2px solid @stroke-light;
      }
      .ant-slider {
        .ant-slider-rail {
          background: @grey-btn;
        }
        .ant-slider-track {
          background: @grey-10;
          height: 6px;
        }
        .ant-slider-handle::after {
          box-shadow: none;
          background-color: @grey-10;
          width: 13px;
          height: 13px;
        }
      }
    }
  }

  .map-wrapper {
    justify-content: flex-end;
    .common-map {
      z-index: 1000;
      height: 350px;
      width: 100%;
      position: relative;
      border-radius: 8px;
      border: 2px solid @stroke-light;
    }

    .map-dropdown {
      position: absolute;
      height: 34px;
      right: 54px;
      top: 15px;
      z-index: 2000;
      width: 150px;
    }

    .detection-map {
      height: 350px;
    }
    .map-container {
      height: 350px;
    }
  }
}
.video-slider {
  .ant-slider {
    margin: -16px 0 8px 0;
    height: 38px;
    display: flex;
    align-items: center;
    background: @grey-11;
    border-radius: 4px;
    padding: 0 10px;
    .ant-slider-handle {
      margin-top: 12px;
      margin-left: 10px;
      &:focus::after {
        box-shadow: none;
      }
    }
    .ant-slider-handle::after {
      box-shadow: none;
      background-color: @grey-10;
      width: 13px;
      height: 13px;
    }
    .ant-slider-track {
      margin-left: 10px;
      background: @grey-10;
      height: 6px;
    }
  }
}

.upload-kml {
  margin: 8px 0;
  justify-content: space-between;
  column-gap: 32px;
  .upload-kml-div {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    font: @body-default-med;
    .kml-upload {
      color: #33aa44;
      cursor: pointer;
    }
    .disabled-kml-upload {
      color: @grey-btn;
    }
    .discard {
      color: #999;
    }
    .save {
      color: #3a4;
    }
  }
}

.sync-top {
  border-bottom: 1px solid #e6e6f2;
  background: @status-white;
}
.ant-table-cell {
  .edit-dropdown {
    font: @body-small-reg;
    color: #33aa44;
    cursor: pointer;
  }
}
.save-details {
  visibility: hidden;
}

// ModelComponents
.modal-components-design {
  top: 4px;
  .ant-modal-close {
    top: 32px;
  }
  .custom-label {
    .ant-form-item {
      margin-bottom: 0;
      margin-top: 24px;
    }
    .add-new-project-save-btn {
      margin-top: 24px;
    }
    &.add-new-project-form {
      > .ant-form-item {
        margin-bottom: 0;
        margin-top: 16px;
        &.project-address {
          .ant-form-item-label label {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          &.report-header {
            margin-top: 32px;
          }
        }
        &:last-child {
          margin-top: 24px;
        }
      }
      .ant-row {
        .ant-form-item {
          margin-bottom: 0;
          margin-top: 16px;
        }
      }
      .ant-form-item-label label {
        display: flex;
        flex-direction: unset;
        &::before {
          order: 2;
          flex: 1;
        }
        .ant-form-item-tooltip {
          order: 3;
          &.pointer {
            cursor: pointer;
          }
        }
      }
    }
    &.setting {
      .project-logo {
        margin-bottom: 0;
        margin-top: 0;
      }
      .ant-divider {
        border: 1px dashed @stroke-light;
      }
    }
  }
  .ant-modal-content {
    padding: 32px;
    border: 1px solid @stroke-light;
    border-radius: 24px;
    .ant-modal-title {
      display: flex;
      align-items: center;
      column-gap: 4px;
      font: @body-large-lar;
      color: @grey-10;
      margin-bottom: 32px;
    }
    .ant-modal-body {
      padding: 0;
      .ant-typography {
        font: @body-default-reg;
        color: @text-light;
      }
    }
    .ant-modal-header {
      padding: 0;
      border-bottom: transparent;
    }
  }
  .workspace-agency-details {
    display: flex;
    column-gap: 12px;
    img {
      width: 100px;
      height: 100px;
      border-radius: 12px;
    }
    .logo-img {
      width: 100px;
      height: 100px;
      border-radius: 12px;
    }
  }
  .workspace-agency-logo {
    font: @body-default-reg;
    color: @text-dark;
  }
  .preferable-image-text {
    font: @body-regular-base;
    color: @grey-02;
    margin: 4px 0 0;
  }
  .edit-delete-btn {
    margin-top: 6px;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}

.assign-member-modal {
  .ant-modal-content {
    min-height: 425px;
    .ant-modal-body {
      .assign-by-email {
        .ant-btn-primary {
          height: 48px;
        }
        .ant-form {
          display: flex;
          gap: 8px;
          .ant-form-item {
            margin: 0;
            &:first-child {
              flex: 1;
            }
            .ant-form-item-control-input {
              min-height: 48px;
              .ant-form-item-control-input-content {
                height: 100%;
                .ant-input-group-wrapper {
                  height: 100%;
                  .ant-input-wrapper {
                    height: 100%;
                    .ant-input {
                      height: 100%;
                    }
                  }
                }
              }
            }
            .primary-main-btn {
              width: 100%;
              height: auto;
              padding: 13px 16px;
              border: 0;
              background: @emerald-main;
              border-radius: 6px;
              font: @body-small-med;
              box-shadow: none;
              color: @product-status-white;
            }
          }
        }
        .assign-member-text {
          color: @status-red;
          font: @body-meta-reg;
          margin: 8px 0 0 0;
        }
      }
      .dashed {
        border-top: 2px dashed @stroke-light;
        border-bottom: 0;
        margin: 32px 0;
      }
      .members-listing {
        p {
          font: @body-small-reg;
          color: @grey-02;
          margin-bottom: 12px;
        }
        .list-scroll {
          max-height: 400px;
          overflow: auto;
          padding-right: 5px;
          .list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            margin-bottom: 16px;
            .user {
              display: flex;
              align-items: center;
              gap: 10px;
              .default-image {
                border: 1px solid @product-stroke-dark;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: @background-2;
              }
              .email {
                max-width: 350px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font: @body-default-reg;
              }
            }
            .roles {
              color: @text-light;
              &.owner {
                padding-right: 64px;
              }
            }
            .ant-select {
              .ant-select-selector {
                border: none;
                box-shadow: none;
                .ant-select-selection-item {
                  color: @text-light;
                }
              }
            }
            .delete-icon {
              border-radius: 20px;
              padding: 8px;
              border: 1px solid @stroke-light;
              display: flex;
            }
          }
        }
      }
    }
  }
}

.sync-table-wrapper {
  margin-top: 16px;
  position: relative;
  .button-wraper {
    display: flex;
    gap: 16px;
    position: absolute;
    right: 0;
    z-index: 99;
  }
  .ant-tabs-top {
    .ant-tabs-nav {
      &::before {
        border-bottom: 0px;
      }
      margin-bottom: 0px;
      .ant-tabs-tab {
        border-top: 1px solid @stroke-light;
        border-right: 1px solid @stroke-light;
        border-left: 1px solid @stroke-light;
        &.ant-tabs-tab-active {
          background-color: @product-background-2;
          font: @body-meta-lar;
          .ant-tabs-tab-btn {
            color: @text-dark;
          }
        }
        &:not(.ant-tabs-tab-active) {
          background-color: @status-white;
          font: @body-small-reg;
          .ant-tabs-tab-btn {
            color: @text-light;
          }
        }
      }
    }
  }
  .ant-tabs-tab {
    padding: 12px 36px !important;
    border-bottom: 0px !important;
  }
  .custom-table {
    .ant-table {
      border-top-left-radius: 0px;
      .ant-table-container {
        .ant-table-thead {
          tr .ant-table-cell:first-child {
            border-top-left-radius: 0px;
          }
        }
      }
    }
  }
  .reset-filters {
    position: absolute;
    right: 0;
    z-index: 99;
  }
}

.video-name-search {
  padding: 10px;
  min-width: 260px;
  max-width: 260px;
  .ant-input {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 2px;
  }
  .ant-space {
    width: 100%;
    .ant-space-item {
      flex: 1;
      .ant-btn {
        box-shadow: none;
        border-radius: 2px;
        width: 100%;
        &.secondary-btn {
          border: 1px solid #d9d9d9;
        }
      }
    }
  }
}

.add-new-detection {
  height: 100%;
  margin-bottom: -24px;
  .add-detection-back-button {
    display: flex;
    align-items: center;
    font: @body-small-reg;
  }
  .sidebar {
    .ant-checkbox-wrapper {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      .ant-checkbox + span {
        color: @grey-09;
        font: @body-regular-base;
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: @grey-07;
          border-color: @grey-07;
        }
      }
    }
  }

  .add-detection-img-title {
    font-size: 22px;
    font-weight: 500;
    line-height: 25.81px;
    margin: 32px 0;
  }

  .annotate-image {
    width: 100%;
  }
  .sidebar {
    padding-right: 32px;
    border-right: 1px solid @stroke-dark;
    .annotate-button {
      gap: 8px;
      flex-wrap: wrap;
      .ant-upload-wrapper {
        flex: 1;
        min-width: 145px;
        .ant-upload {
          width: 100%;
        }
      }
      .ant-btn {
        flex: 1;
        min-width: 145px;
        width: 100%;
      }
    }
    .sidebar-sticky {
      position: sticky;
      top: 0;
      .dashed-separator {
        height: 1px;
        margin: 24px 0;
        border: 1px dashed @stroke-dark;
      }
      .ai-powered-info {
        border-radius: 7px;
        border: 1px solid @stroke-light;
        padding: 12px;
        background: @product-background-2;

        .ai-powered-title {
          font: @body-med-extra-lar;
          color: @status-blue;
        }
        .ai-powered-description {
          color: @product-text-dark;
          font: @body-reg-medium;
        }
      }
    }
  }
  .form-section {
    margin: 32px 0 0 32px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font: @body-large-med;
      }
    }
    .form {
      display: block;
      padding-top: 16px;
      .border-dashed {
        border: 1px dashed @stroke-dark;
      }
      .ant-btn-link {
        font: @body-default-reg;
      }
      .delete-detection {
        &.ant-btn {
          min-width: auto;
          border-color: @status-red;
        }
      }
      .property-section {
        .ant-table-wrapper {
          .ant-table-tbody {
            .ant-table-cell {
              .property-name {
                .text {
                  max-width: 200px;
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .recommendations {
                display: flex;
                align-items: center;
                .description {
                  max-width: 200px;
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .description-count {
                  margin-left: 4px;
                  font-size: 12px;
                  background: @product-background-2;
                  border: 1px solid @stroke-light;
                  padding: 6px 8px 6px 8px;
                  border-radius: 50%;
                  flex-shrink: 0;
                }
              }
              .actions {
                color: @primary-dark;
              }
            }
          }
        }
        .detection-details-scroll {
          height: 40vh;
          overflow: auto;
          padding-bottom: 10px;
          .detection-property-list-img {
            width: 25px;
            height: 24px;
            border-radius: 2px;
          }
        }
      }
    }
  }
  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
      .ant-row {
        height: 100%;
      }
    }
  }
}

.property-modal {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        margin-bottom: 24px;
      }
    }
    .ant-modal-body {
      .form-overflow {
        max-height: 464px;
        overflow: auto;
        padding-right: 10px;
        .ant-form-item {
          margin-top: 24px;
          &:first-child {
            margin-top: 0;
          }
          &.other-property-input {
            margin-top: 8px;
          }
          .ant-checkbox-group {
            width: 100%;
          }
          .ant-checkbox-group {
            .ant-checkbox-wrapper {
              width: 100%;
              padding: 8px 0;
              &.ant-checkbox-wrapper-checked {
                span:last-child {
                  textarea {
                    color: @secondary-btn;
                    box-shadow: 0px 0px 6px 0px #00844066;
                  }
                }
              }
              .ant-input-disabled {
                background-color: transparent;
                color: @text-light;
                cursor: initial;
              }
              span:last-child {
                flex: 1;
              }
            }
          }
          &.other-recommendation {
            margin-top: 0;
          }
          &.recommendation-required {
            .ant-form-item-label {
              label::before {
                display: inline-block;
                margin-inline-end: 4px;
                color: #ff4d4f;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: '*';
              }
            }
          }
          .ant-form-item-control-input {
            .standard-value {
              font: @body-regular-base;
              color: @grey-02;
              margin: 8px 0 0;
            }
          }
        }
      }
    }
  }
}

.comment-drawer {
  .ant-drawer-body {
    padding: 12px 24px 12px 16px;
    display: flex;
    flex-direction: column;
    .detection-id {
      margin-bottom: 24px;
      font: @body-small-med;
      margin-top: 0;
      color: @grey-02;
      b {
        color: @grey-10;
        font: @body-medium-base;
      }
    }
    .comment-box {
      display: flex;
      gap: 12px;
      .comment-form {
        flex: 1;
        .text-box {
          margin-bottom: 6px;
        }
      }
    }
    .border-dashed {
      border: 1px dashed @stroke-dark;
      margin: 24px 0;
    }
    .no-comment {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: @grey-02;
    }
    .comment-overflow {
      flex: 1;
      overflow: auto;
      .comment-list {
        .comment {
          margin-bottom: 24px;
          border-radius: 8px;
          background-color: @product-background-2;
          display: flex;
          gap: 12px;
          padding: 16px;
          .comment-form {
            flex: 1;
            .comment-form-comment {
              font: @body-default-reg;
              color: @grey-10;
              margin: 0;
              word-break: break-all;
            }
            .comment-form-sub-name {
              font: @body-medium-base;
              color: @grey-10;
            }
            .comment-time {
              font: @body-regular-base;
              color: @grey-02;
            }
          }
        }
      }
    }
    .ant-spin-nested-loading {
      flex: 1;
      overflow: auto;
      .ant-spin-container {
        height: 100%;
      }
    }
  }
}

.property-actions {
  .ant-dropdown-menu {
    padding: 8px 12px;
    .ant-dropdown-menu-item {
      padding: 8px 4px;
      &.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled) {
        color: @status-red;
      }
      &.error-color {
        color: @status-red;
      }
    }
    .ant-dropdown-menu-item-divider {
      margin: 4px;
    }
  }
}

// dropdown-card
.project-card-dropdown {
  &.ant-dropdown-placement-bottom {
    position: fixed;
    top: 20px;
    right: 30px;
  }
  .ant-dropdown-menu {
    padding: 8px 8px;
    min-width: 180px;
    .ant-dropdown-menu-item-divider {
      background-color: @stroke-light;
      margin: 4px;
    }
    .ant-dropdown-menu-item {
      padding: 8px 8px;
      &.error-color {
        color: @status-red;
      }
    }
  }
}

// ####### consolidated view #####
.consolidated-view {
  .consolidated-view-map {
    height: calc(100vh - 160px);
    .google-map-div {
      position: relative;
    }
    .total-distance-covered {
      position: absolute;
      top: 50px;
      right: 50px;
      background: @status-white;
      border-radius: 8px;
      border: 2px solid @stroke-light;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 16px 16px;
      font: @body-default-med;
      color: @primary-dark;
    }
  }
  .consolidated-view-content {
    height: 100%;
    overflow: auto;
  }
  .synced-video-count {
    font: @body-extra-large;
    color: @grey-10;
  }
  .sync-videos {
    border-radius: 8px;
    border: 1px solid @stroke-light;
    background-color: @product-background-2;
    .sync-video-duration {
      border: 1px solid @stroke-light;
      border-radius: 1000px;
      padding: 6px 12px;
      background: @status-white;
      max-height: 29px;
    }
    .sync-video-title {
      font: @body-bold-lar;
      color: @grey-09;
    }
    .sync-video-distance {
      font: @body-medium-base;
      color: #333;
    }
    .sync-video-chainage-title {
      font: @body-regular-base;
      color: @grey-02;
    }
    .sync-video-chainage {
      font: @body-reg-medium;
      color: @grey-10;
    }
  }
}

//  ####################### Reports ########################################
.reports-title {
  font: @body-bold-lar;
  color: @accent-dark;
}
.reports-description {
  p {
    font: @body-reg-medium;
    color: @text-light;
    margin: 0;
  }
}
.reportsInview {
  overflow: auto;
  height: calc(100vh - 200px);
}

// ############################ Traffic Analysis ############################
.analysis-title {
  font: @body-large-med;
  color: @accent-dark;
}
.traffic-analysis {
  border-radius: 12px;
  border: 1px @stroke-light solid;
  background: #f4f6f8;
  padding: 16px;
  .steps-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
    height: 100%;

    .ant-form-item {
      margin-bottom: 0;
    }
    .steps-footer {
      .ant-btn-disabled {
        border-color: transparent;
        .ant-btn-icon {
          svg {
            path {
              stroke: @status-white;
            }
          }
        }
      }
    }
    .time-duration {
      .ant-form-item {
        .ant-picker {
          width: 100%;
          height: 48px;
        }
      }
    }
  }
  .step {
    font: @body-bold-med;
    color: @accent-dark;
  }
  .description {
    font: @body-reg-medium;
    color: @text-light;
  }
  .csv-file-name {
    font: @body-bold-med;
  }
}

.time-picker {
  .ant-picker-time-panel-column::after {
    display: none;
  }
  .ant-picker-footer {
    .ant-picker-ok {
      .ant-btn {
        min-width: auto;
      }
    }
    .ant-picker-ranges {
      margin: 0;
      gap: 20px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//  ###################################### Detection ##########################################
div[aria-roledescription='map'] {
  div:nth-child(4) {
    div:first-child {
      .gm-style-iw-a {
        display: none;
      }
    }
  }
}

.image-editor-modal {
  font-family: var(--first-font);
  * {
    font-family: inherit !important;
  }
  *[color='primary'] {
    background-color: @primary-dark;
    &:hover {
      background-color: #54b860;
    }
  }
  .ant-modal-content {
    .ant-modal-body {
      height: 85vh;
      .FIE_tabs {
        padding: 0;
        box-shadow: none;
        *[aria-selected='true'] {
          background-color: #d8f3dc !important;
          border: 1px solid @primary-dark;
          color: @primary-dark !important;
          * {
            color: inherit !important;
          }
        }
        *[aria-selected='false'] {
          background-color: transparent !important;
          border: 1px solid @stroke-light;
          color: @text-light !important;
          * {
            color: inherit !important;
          }
        }
        .FIE_tab {
          padding: 10px;
          width: 112px !important;
          height: 110px !important;
          * {
            font: @body-bold-med !important;
          }
          &:first-child {
            .SfxLabel-text {
              font-size: 0 !important;
              &::after {
                content: 'Annotate';
                font: @body-bold-med !important;
              }
            }
          }
          &:nth-child(2) {
            .SfxLabel-text {
              font-size: 0 !important;
              &::after {
                content: 'Draw';
                font: @body-bold-med !important;
              }
            }
          }
        }
      }
      .FIE_editor-content {
        width: calc(100% - 112px);
        .FIE_tools-bar-wrapper {
          max-height: unset;
          .FIE_tools-bar {
            *[aria-selected='true'] {
              background-color: #d8f3dc !important;
              border: 1px solid @primary-dark;
              color: @primary-dark !important;
              border-radius: 6px;
              * {
                color: inherit !important;
              }
            }
            *[aria-selected='false'] {
              background-color: transparent !important;
              color: @text-light !important;
              * {
                color: inherit !important;
              }
            }
            .FIE_carousel-item {
              .FIE_watermark-selected-item {
                height: 84px;
                width: 84px;
                text-align: center;
              }
            }
            .FIE_watermark-add-wrapper {
              button {
                display: none;
              }
            }
          }
        }
      }
      .FIE_topbar {
        padding: 24px 0 !important;
        border-bottom: 0;
        .FIE_topbar-history-buttons {
          button {
            height: 24px;
            width: 24px;
            margin: 0 8px;
            padding: 4px;
            svg {
              width: 100%;
              height: 100%;
            }
            &.reset-button {
              height: 40px;
              width: auto;
              border-radius: 6px;
            }
          }
        }
        .FIE_topbar-save-wrapper {
          width: auto !important;
          .FIE_topbar-save-button {
            height: 40px;
            border-radius: 6px;
            padding: 12px !important;
            span {
              font: @body-bold-med !important;
            }
            & > span {
              &:after {
                content: '& Continue';
                margin-left: 4px;
              }
            }
          }
        }
      }
      .reset-image {
        position: absolute;
        top: 54px;
        right: 174px;
        font-size: 20px;
        svg {
          fill: @status-light-gray;
        }
      }
      .FIE_spinner {
        &::after {
          border-color: @primary-dark transparent !important;
        }
      }
    }
  }
  .ant-modal-close {
    inset-inline-end: 24px;
  }
}

.SfxModal-Wrapper {
  * {
    font-family: var(--first-font) !important;
  }
  .SfxModal-Container {
    border-radius: 16px;
    max-width: 400px !important;
    .SfxModalTitle-Icon {
      svg {
        path {
          fill: rgba(0, 0, 0, 0.25);
        }
      }
    }
    .SfxModalTitle-LabelPrimary {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.88);
    }
    .SfxModalTitle-LabelSecondary {
      margin: 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: @text-light;
      text-align: center;
    }
    .SfxModalActions-root {
      .SfxButton-root {
        height: 40px;
        border-radius: 6px;
        padding: 12px;
        &:not(:first-child) {
          background-color: @primary-dark !important;
          &:hover {
            background-color: #54b860 !important;
          }
        }
      }
    }
  }
}

//######################### Graphs ####################################
.graph-container {
  display: flex;
  justify-content: center;
  background: @status-white;
  border-radius: 24px;
  border: 2px @stroke-light solid;
}

.report-list {
  .ant-table {
    .ant-table-container {
      .ant-table-content {
        .ant-table-tbody {
          .ant-table-cell {
            .generating-report {
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
              padding-right: 95px;
            }
            .ant-btn {
              width: 200px;
              border-radius: 6px;
              border: 1.5px solid @accent-dark;
              background: transparent;
              &.generating {
                border: 1px solid @grey-btn;
                span {
                  color: @grey-btn;
                }
              }
              &.failed {
                border: 1px solid @status-red;
                span {
                  color: @status-red;
                }
              }
              span {
                font: @body-default-lar;
              }
            }
            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

.uploaded_csv_error {
  font: @body-regular-base;
  color: @status-red;
}

.change-password {
  .ant-modal-content {
    border-radius: 24px;
    .ant-modal-header {
      .ant-modal-title {
        font: @body-large-lar;
      }
      margin-bottom: 32px;
    }
    .ant-modal-body {
      .ant-form-item {
        &.submit-button {
          padding-top: 8px;
        }
        .ant-form-item-label {
          label {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }
}

.account-delete-modal {
  .ant-modal-body {
    .ant-form-item {
      .ant-form-item-label {
        label {
          flex-direction: row-reverse;
        }
      }
    }
    .ant-divider {
      border: 1px dashed @stroke-light;
    }
  }
}

.detection-type-filter {
  padding: 4px 16px;
  .ant-space {
    .ant-space-item {
      border-bottom: 1px solid @stroke-light;
      padding: 8px 0;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.ant-table-filter-dropdown {
  .see-all-menu-filter {
    padding: 8px 8px;
    .ant-dropdown-menu-item {
      padding: 4px 4px;
    }
    .ant-dropdown-menu-item-divider {
      margin: 4px 4px;
      background: @stroke-light;
    }
  }
}

.ant-table-filter-dropdown {
  .checkbox-filter-wrapper {
    padding: 12px 16px;
    max-height: 250px;
    max-width: 250px;
    overflow: auto;
    .ant-space {
      width: 100%;
      .ant-space-item {
        .ant-divider {
          margin: 8px 0;
        }
        .ant-checkbox-wrapper {
          .checkbox-text {
            white-space: nowrap;
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .checkbox-filter-wrapper-btn-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    border-top: 1px solid #0000000f;
    padding: 8px;
    .ant-btn {
      min-width: auto;
    }
  }
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: @primary-dark;
  color: @primary-dark;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  text-align: right;
  &::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: @primary-dark;
    color: @primary-dark;
    left: -10px;
  }
  &::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: @primary-dark;
    color: @primary-dark;
  }
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.start-end-icon {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 0;
  top: -30px;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.magic-blue {
  display: flex;
  column-gap: 8px;
}

// ###################### Video player ###################################

.video-wrapper {
  position: relative;
  max-width: 1080;
  .video {
    height: auto;
    width: 100%;
  }

  .ai-processing-div {
    display: flex;
    column-gap: 13px;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 10px;
    margin: 24px;
    .magic-pen {
      background: black;
      padding: 10px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: @status-blue;
    }
    .ant-switch {
      background: @product-background-2;
      border: 1px @stroke-dark solid;
      &.ant-switch-checked {
        background-color: @background-slider-blue;
        border: 1px @border-radius-blue solid;
        .ant-switch-handle {
          inset-inline-start: calc(100% - 30px);
        }
        .ant-switch-handle:before {
          background: @status-blue;
          border-radius: 50%;
        }
      }
      .ant-switch-handle {
        width: 32px;
        height: 32px;
        top: -5px;
        inset-inline-start: -2px;
      }
      .ant-switch-handle:before {
        background: @grey-02;
        border-radius: 50%;
      }
      .ant-switch-inner {
        width: 56px;
        height: 24px;
      }
    }
  }
}
.view-details-ai-process {
  display: flex;
  align-items: center;
  .ai-processing-title-terminated {
    color: @status-red;
    font: @body-bold-lar;
  }
  .ai-processing-title-completed {
    color: @status-blue;
    font: @body-bold-lar;
  }
}

.sync-container {
  .ant-typography {
    font: @body-default-med;
  }
  .processing-completed {
    .ant-typography {
      color: @status-green;
    }
  }
  .processing-in_progress {
    .ant-typography {
      color: @status-yellow;
    }
  }
  .processing-terminating {
    .ant-typography {
      color: @status-yellow;
    }
  }
  .processing-terminated {
    .ant-typography {
      color: @text-light;
    }
  }
  .ai-processing-status {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .separate {
    width: 1px;
    height: 20px;
    background: @background;
  }
}

.video-processing-status,
.image-processing-status {
  .status-in-progress {
    display: flex;
    align-items: center;
    column-gap: 4px;
    border-radius: 6px;
    padding: 4px 12px;
    border: 1.5px solid @status-yellow;
    color: @status-yellow;
    font: @body-medium-base;
  }
  .status-terminated {
    color: @primary-dark;
    font: @body-regular-base;
  }
  .status-completed {
    display: flex;
    align-items: center;
    column-gap: 4px;
    border-radius: 6px;
    padding: 4px 12px;
    border: 1.5px solid @primary-light;
    color: @primary-light;
    font: @body-medium-base;
  }
  .image-status-in-progress {
    display: flex;
    align-items: center;
    column-gap: 4px;
    border-radius: 6px;
    padding: 4px 12px;
    border: 1.5px solid @status-yellow;
    color: @status-yellow;
    font: @body-medium-base;
    background: #fcf6e1;
  }
}

.highlighted-row {
  background-color: @background-selected;
}

.FIE_canvas-container {
  .FIE_canvas-node {
    background: @status-white;
  }
}
.color-wrapper {
  display: flex;
  align-items: center;
  column-gap: 4px;
  .btn-hollow {
    height: 20px;
    width: 20px;
    background: transparent;
    border: 1px solid @status-med-grey;
    border-radius: 4px;
    padding: 0;
    margin: 6px;
    cursor: pointer;
  }
}

.FIE_annotations-options {
  span {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    .FIE_color-picker-triggerer {
      height: 20px;
      width: 20px;
      margin: 6px;
    }
  }
  .FIE_color-picker-triggerer {
    height: 20px;
    width: 20px;
    margin: 6px;
  }
}

.FIE_spinner-wrapper {
  svg {
    path {
      fill: #33aa44;
    }
  }
}
