@import url(./variable.less);

h1,
.heading-h1 {
  font: @heading-h1;
}
h2,
.heading-h2 {
  font: @heading-h2;
}
h3,
.heading-h3 {
  font: @heading-h3;
}
h4 {
  font: @heading-h4;
}
h5 {
  font: @heading-h5;
}
h6 {
  font: @heading-h6;
}
