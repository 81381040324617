/*breakpoint mixin start here*/
/*breakpoint mixin end here*/
:root {
  --first-font: 'Space Grotesk', sans-serif;
  --second-font: 'Michroma', sans-serif;
  --heading-h1: normal 400 50px/60px var(--second-font);
  --heading-h2: normal 400 40px/48px var(--second-font);
  --heading-h3: normal 400 32px/48px var(--second-font);
  --heading-h4: normal 400 24px/30px var(--second-font);
  --heading-h5: normal 400 22px/28px var(--second-font);
  --heading-h6: normal 500 18px/28px var(--first-font);
  --body-large-regular: normal 400 18px/28px var(--first-font);
  --body-large-medium: normal 500 18px/28px var(--first-font);
  --body-large-bold: normal 700 18px/28px var(--first-font);
  --body-default-regular: normal 400 16px/24px var(--first-font);
  --body-default-medium: normal 500 16px/24px var(--first-font);
  --body-default-bold: normal 700 16px/24px var(--first-font);
  --body-small-regular: normal 400 14px/22px var(--first-font);
  --body-small-medium: normal 500 14px/22px var(--first-font);
  --body-small-bold: normal 700 14px/22px var(--first-font);
  --body-meta-regular: normal 400 14px/22px var(--first-font);
  --body-meta-medium: normal 500 14px/22px var(--first-font);
  --body-meta-bold: normal 700 14px/22px var(--first-font);
  --body-bold-medium: normal 700 16px/24px var(--first-font);
  --body-bold-large: normal 700 18px/28px var(--first-font);
  --body-reg-medium: normal 400 16px/24px var(--first-font);
  --body-reg-medium-michroma: normal 400 16px/24px var(--second-font);
  --body-regular-base: normal 400 14px/20px var(--first-font);
  --body-medium-base: normal 500 14px/20px var(--first-font);
  --body-extra-large: normal 500 22px/26px var(--first-font);
  --body-medium-extra-large: normal 500 20px/30px var(--first-font);
}
/*typography css end here*/
/**https://ant.design/theme-editor**/
#root {
  height: 100vh;
}
/********** margin padding function start here **************/
.p-0 {
  padding: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.m-0 {
  margin: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.-mt-0 {
  margin-top: -0px;
}
.-mb-0 {
  margin-bottom: -0px;
}
.p-2 {
  padding: 2px;
}
.pt-2 {
  padding-top: 2px;
}
.pl-2 {
  padding-left: 2px;
}
.pr-2 {
  padding-right: 2px;
}
.pb-2 {
  padding-bottom: 2px;
}
.py-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.px-2 {
  padding-left: 2px;
  padding-right: 2px;
}
.m-2 {
  margin: 2px;
}
.mt-2 {
  margin-top: 2px;
}
.ml-2 {
  margin-left: 2px;
}
.mr-2 {
  margin-right: 2px;
}
.mb-2 {
  margin-bottom: 2px;
}
.-mt-2 {
  margin-top: -2px;
}
.-mb-2 {
  margin-bottom: -2px;
}
.p-4 {
  padding: 4px;
}
.pt-4 {
  padding-top: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.pr-4 {
  padding-right: 4px;
}
.pb-4 {
  padding-bottom: 4px;
}
.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}
.m-4 {
  margin: 4px;
}
.mt-4 {
  margin-top: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.-mt-4 {
  margin-top: -4px;
}
.-mb-4 {
  margin-bottom: -4px;
}
.p-6 {
  padding: 6px;
}
.pt-6 {
  padding-top: 6px;
}
.pl-6 {
  padding-left: 6px;
}
.pr-6 {
  padding-right: 6px;
}
.pb-6 {
  padding-bottom: 6px;
}
.py-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.px-6 {
  padding-left: 6px;
  padding-right: 6px;
}
.m-6 {
  margin: 6px;
}
.mt-6 {
  margin-top: 6px;
}
.ml-6 {
  margin-left: 6px;
}
.mr-6 {
  margin-right: 6px;
}
.mb-6 {
  margin-bottom: 6px;
}
.-mt-6 {
  margin-top: -6px;
}
.-mb-6 {
  margin-bottom: -6px;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.m-8 {
  margin: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.-mt-8 {
  margin-top: -8px;
}
.-mb-8 {
  margin-bottom: -8px;
}
.p-10 {
  padding: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.m-10 {
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.-mt-10 {
  margin-top: -10px;
}
.-mb-10 {
  margin-bottom: -10px;
}
.p-12 {
  padding: 12px;
}
.pt-12 {
  padding-top: 12px;
}
.pl-12 {
  padding-left: 12px;
}
.pr-12 {
  padding-right: 12px;
}
.pb-12 {
  padding-bottom: 12px;
}
.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.m-12 {
  margin: 12px;
}
.mt-12 {
  margin-top: 12px;
}
.ml-12 {
  margin-left: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.-mt-12 {
  margin-top: -12px;
}
.-mb-12 {
  margin-bottom: -12px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.-mt-16 {
  margin-top: -16px;
}
.-mb-16 {
  margin-bottom: -16px;
}
.p-18 {
  padding: 18px;
}
.pt-18 {
  padding-top: 18px;
}
.pl-18 {
  padding-left: 18px;
}
.pr-18 {
  padding-right: 18px;
}
.pb-18 {
  padding-bottom: 18px;
}
.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}
.px-18 {
  padding-left: 18px;
  padding-right: 18px;
}
.m-18 {
  margin: 18px;
}
.mt-18 {
  margin-top: 18px;
}
.ml-18 {
  margin-left: 18px;
}
.mr-18 {
  margin-right: 18px;
}
.mb-18 {
  margin-bottom: 18px;
}
.-mt-18 {
  margin-top: -18px;
}
.-mb-18 {
  margin-bottom: -18px;
}
.p-20 {
  padding: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.m-20 {
  margin: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.-mt-20 {
  margin-top: -20px;
}
.-mb-20 {
  margin-bottom: -20px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.-mt-24 {
  margin-top: -24px;
}
.-mb-24 {
  margin-bottom: -24px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.-mt-32 {
  margin-top: -32px;
}
.-mb-32 {
  margin-bottom: -32px;
}
.p-38 {
  padding: 38px;
}
.pt-38 {
  padding-top: 38px;
}
.pl-38 {
  padding-left: 38px;
}
.pr-38 {
  padding-right: 38px;
}
.pb-38 {
  padding-bottom: 38px;
}
.py-38 {
  padding-top: 38px;
  padding-bottom: 38px;
}
.px-38 {
  padding-left: 38px;
  padding-right: 38px;
}
.m-38 {
  margin: 38px;
}
.mt-38 {
  margin-top: 38px;
}
.ml-38 {
  margin-left: 38px;
}
.mr-38 {
  margin-right: 38px;
}
.mb-38 {
  margin-bottom: 38px;
}
.-mt-38 {
  margin-top: -38px;
}
.-mb-38 {
  margin-bottom: -38px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.-mt-40 {
  margin-top: -40px;
}
.-mb-40 {
  margin-bottom: -40px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.py-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.px-48 {
  padding-left: 48px;
  padding-right: 48px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.-mt-48 {
  margin-top: -48px;
}
.-mb-48 {
  margin-bottom: -48px;
}
/********** margin padding function end here **************/
.m-hide {
  display: block;
}
@media only screen and (max-width: 767px) {
  .m-hide {
    display: none;
  }
}
.d-hide {
  display: none;
}
@media only screen and (max-width: 767px) {
  .d-hide {
    display: block;
  }
}
.wrapper {
  padding: 16px 32px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    font-size: 14px;
    padding: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 16px;
    padding-bottom: 0;
  }
}
.responsive-table {
  width: 100%;
  overflow: auto;
}
.ant-table .ant-table-tbody .anticon {
  font-size: 20px;
  padding: 0 4px;
}
.ant-table .ant-table-tbody .ant-btn {
  margin: 0;
  padding: 0;
  height: auto;
}
.pointer {
  cursor: pointer;
}
.pointer-none {
  pointer-events: none;
}
.grab {
  cursor: grab;
}
.radius {
  border-radius: 50%;
}
.height-100 {
  height: 100%;
}
.height-calc-60 {
  height: calc(100% - 60px);
}
/********** ant-button start here **************/
.ant-btn {
  padding: 0 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font: var(--body-bold-large);
}
.ant-btn:disabled {
  background: transparent;
  border-color: #cccccc;
  color: #cccccc;
}
.ant-btn.ant-btn-default.ant-btn-dangerous:disabled {
  border-color: #cccccc;
}
.ant-btn.ant-btn-primary:disabled {
  border-color: #cccccc;
  background: #cccccc;
  color: #ffffff;
}
.ant-btn.ant-btn-lg {
  padding: 0 24px;
  min-width: 150px;
  font: var(--body-bold-large);
}
.ant-btn.ant-btn-sm {
  font-size: 16px;
  padding: 0 12px;
  min-width: 100px;
  font: var(--body-bold-medium);
}
.ant-btn.secondary-btn {
  background: #263a30;
  color: #ffffff;
  border-color: #263a30;
}
.ant-btn.secondary-btn:disabled {
  border-color: #cccccc;
  background: #cccccc;
  color: #ffffff;
}
.ant-btn.secondary-btn:disabled:hover {
  border-color: #cccccc;
}
.ant-btn.secondary-btn:not(:disabled):hover {
  background-color: #263a30;
}
.ant-btn.secondary-btn:hover {
  color: #ffffff;
  border-color: #263a30;
  opacity: 0.8;
}
.ant-btn.secondary-btn.border-btn {
  background: transparent;
  color: #263a30;
}
.ant-btn.secondary-btn.border-btn:disabled {
  background: transparent;
  border-color: #cccccc;
  color: #cccccc;
}
.ant-btn.secondary-btn.border-btn:disabled svg path {
  stroke: #cccccc;
}
.ant-btn.secondary-btn.border-btn:not(:disabled):hover {
  background-color: transparent;
}
.ant-btn.manually-ai-process {
  background: #035fc4;
  color: #ffffff;
  border-color: #035fc4;
}
.ant-btn.manually-ai-process:hover {
  background: #035fc4;
  color: #ffffff;
  border-color: #035fc4;
}
.ant-btn.manually-ai-process:disabled {
  background: transparent;
  border-color: #cccccc;
  color: #cccccc;
}
.ant-btn.manually-ai-process:disabled svg path {
  stroke: #cccccc;
}
.ant-btn.stop-ai-process {
  color: #e64e2c;
  border-color: #e64e2c;
  height: 28px;
  min-width: 136px;
  border-radius: 4px;
}
.ant-btn.stop-ai-process:hover {
  color: #e64e2c;
  border-color: #e64e2c;
}
.ant-btn.view-details-ai-process {
  color: #263a30;
  border-color: #263a30;
  font: var(--body-default-medium);
  height: 28px;
  min-width: 136px;
  border-radius: 4px;
}
.ant-btn.view-details-ai-process:hover {
  color: #263a30;
  border-color: #263a30;
}
.ant-btn.ant-btn-dashed {
  border-color: #6c6f74;
  color: #6c6f74;
}
.ant-btn.ant-btn-dashed.upload-csv {
  border-radius: 12px;
}
.ant-btn.ant-btn-link {
  padding: 0;
  height: auto;
}
.ant-btn.ant-btn-link.custom-link {
  font: var(--body-bold-medium);
  color: #514f55;
}
/********** ant-button end here **************/
/********** ant select start tag **************/
.ant-select {
  font: var(--body-reg-medium);
}
.ant-select.ant-select-open .ant-select-selection-item {
  color: #010101;
}
.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
  background-color: transparent;
}
/********** ant select tag end here**************/
/********** ant Dropdown tag start here**************/
.ant-dropdown.ant-dropdown-placement-bottomLeft {
  padding: 12px 16px;
  font: var(--body-regular-base);
  color: #514f55;
}
.ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu-item .ant-checkbox-wrapper + span {
  font: var(--body-regular-base);
  color: #514f55;
}
.ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  font: var(--body-regular-base);
  color: #514f55;
}
/********** text DropDown ends here **************/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-end {
  text-align: end;
}
.col-gap-32 {
  column-gap: 32px;
}
.col-gap-16 {
  column-gap: 16px;
}
/********** text alignment end here **************/
/********** flex box class start here **************/
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-horizontal-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row-reverse;
}
.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  word-break: break-all;
}
.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.flex-1 {
  flex: 1;
}
.d-none {
  display: none;
}
/********** flex box class end here **************/
.width-percent-20 {
  width: 20%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-80 {
  width: 80%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-25 {
  width: 25%;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.site-result-demo-error-icon {
  color: #ff4d4f;
}
.b-0 {
  border: none;
}
.b-b-2 {
  border: 2px solid black;
}
/*position class start here*/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.sticky {
  position: sticky;
}
/*position class end here*/
.header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-card-head-title {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-page-header-heading {
    padding: 0;
    min-height: 48px;
  }
}
#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  font-family: PuHuiTi, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji, segoe ui emoji, Segoe UI Symbol, noto color emoji, sans-serif;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #121d45;
}
#logo img {
  height: 32px;
}
.delete-icon {
  color: #e92424;
  cursor: pointer;
}
.error-color {
  color: #e64e2c;
}
/*table draggable css start here*/
.row-dragging {
  background: #fafafa;
  border: 1px solid #cccccc;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
/*sidebar custom less start here*/
.responsive-logo img {
  width: 44px;
}
.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0 !important;
  left: 0;
}
.ant-layout-sider {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
@media only screen and (max-width: 767px) {
  .ant-layout-sider {
    width: 0;
    max-width: 0;
    position: fixed !important;
    height: 100%;
    left: -340px;
    z-index: 12;
  }
}
.app-header,
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ant-layout.ant-layout-has-sider {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .ant-layout-header {
    padding: 0 16px !important;
  }
}
header.ant-layout-header.site-layout-sub-header-background.app-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6f2;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #010101;
  height: inherit;
  align-items: center;
  padding: 6px 32px;
  background: #ffffff;
}
@media only screen and (max-width: 767px) {
  .overlay-disable {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #000000;
    opacity: 0.6;
    z-index: 11;
  }
}
/*sidebar custom less end here*/
@media only screen and (max-width: 767px) {
  .ant-layout-footer {
    padding: 16px 50px;
  }
}
/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}
:hover::-webkit-scrollbar-thumb {
  border-left: 8px solid rgba(0, 0, 0, 0.3);
  border-bottom: 8px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}
/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
}
.ant-body-scroll .ant-card-head {
  position: relative;
  z-index: 1;
}
.ant-body-scroll .ant-page-header-heading {
  min-height: 48px;
}
.ant-body-scroll > .ant-card-body {
  flex: 1 1;
  height: 100%;
  padding: 0;
  overflow: hidden;
}
.ant-body-scroll > .ant-card-body .card-body-wrapper {
  height: 100%;
  overflow: auto;
  padding: 24px;
}
.ant-card-small > .ant-card-body .card-body-wrapper {
  padding: 12px;
}
.ant-card .ant-card-actions > li {
  margin: 12px 24px;
}
.title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;
}
.title svg {
  margin-right: 5px;
  font-size: 16px;
}
/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
}
/*fleg dropdown css end here*/
.delete-account-modal .delete-account-description,
.ai-process-terminated .delete-account-description,
.ai-process-completed .delete-account-description {
  font: var(--body-default-regular);
  color: #6c6f74;
}
.delete-account-modal .delete-account-title,
.ai-process-terminated .delete-account-title,
.ai-process-completed .delete-account-title {
  font: var(--body-default-bold);
  color: #514f55;
}
.delete-account-modal .progress-processing,
.ai-process-terminated .progress-processing,
.ai-process-completed .progress-processing {
  color: #010101;
  font: var(--body-default-medium);
}
.delete-account-modal .progress-processing-complete,
.ai-process-terminated .progress-processing-complete,
.ai-process-completed .progress-processing-complete {
  color: #035fc4;
  font: var(--body-default-medium);
}
.delete-account-modal .description,
.ai-process-terminated .description,
.ai-process-completed .description {
  color: #514f55;
  font: var(--body-reg-medium);
}
.ant-popover-arrow {
  display: none;
}
.ql-container .ql-editor {
  min-height: 211px;
}
.popconfirm-buttons .ant-popover-content .ant-popover-inner .ant-popconfirm-inner-content .ant-popconfirm-buttons {
  margin-top: 24px;
}
.breadcrumb .ant-breadcrumb ol li span {
  display: block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.breadcrumb .ant-breadcrumb ol li:last-child span {
  color: #33aa44;
  font-weight: 700;
  line-height: 20px;
  overflow: visible;
}
h1,
.heading-h1 {
  font: var(--heading-h1);
}
h2,
.heading-h2 {
  font: var(--heading-h2);
}
h3,
.heading-h3 {
  font: var(--heading-h3);
}
h4 {
  font: var(--heading-h4);
}
h5 {
  font: var(--heading-h5);
}
h6 {
  font: var(--heading-h6);
}
.content-top .content-top-heading {
  font: var(--body-large-medium);
  color: #010101;
}
.content-top .searchBox .ant-btn-icon-only {
  background: #ffffff;
  min-width: 40px;
  border-color: #d2d2e1;
}
.content-top .searchBox .ant-btn-icon-only .anticon-search {
  color: black;
}
.content-top .add-on-button {
  justify-content: center;
  gap: 6px;
  text-align: center;
}
.content-top .project-search-input .ant-input-affix-wrapper {
  border-radius: 6px;
  color: #6c6f74;
}
.content-top .project-search-input .ant-input-affix-wrapper .ant-input::placeholder {
  color: #6c6f74;
  font: var(--body-meta-medium);
}
.ant-modal-body .invite-user-save {
  width: 100%;
  height: 60px;
  font: var(--body-large-bold);
}
.ant-modal-body .FIE_editor-content {
  position: relative;
}
.ant-modal-body .FIE_editor-content .FIE_watermark-options-wrapper {
  position: absolute;
  top: calc(100% - 128px);
  left: 0px;
  width: calc(100% - 366px);
}
.ant-modal-body .FIE_tools-bar-wrapper .FIE_tools-bar .FIE_watermark-add-wrapper div:nth-child(3) {
  min-width: 300px;
}
.ant-modal-body .FIE_tools-bar-wrapper .FIE_tools-bar .FIE_watermark-add-wrapper div:nth-child(3) #myInput {
  padding-right: 35px !important;
}
.ant-modal-body .FIE_tools-bar-wrapper input {
  padding-right: 30px;
}
.inside-project-container .container-div .ant-typography {
  font: var(--body-default-medium);
  color: #010101;
}
.inside-project-container .project-details .ant-typography {
  font: var(--body-small-regular);
  color: #999999;
}
.inside-project-container .project-details p {
  font: var(--body-default-regular);
  color: #514f55;
  margin: 0;
  margin-top: 8px;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  /* Number of lines */
  overflow: hidden;
  text-overflow: ellipsis;
}
.inside-project-container .project-details .project-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inside-project-container .assign-members {
  background: transparent;
}
.inside-project-container .videos-list .ant-table-wrapper .ant-table-filter-column {
  justify-content: unset;
}
.inside-project-container .videos-list .ant-table-wrapper .ant-table-column-title {
  min-width: unset;
  flex: unset;
}
.add-new-project-modal .ant-modal-content {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.add-new-project-modal .ant-modal-content .map-img {
  height: 100%;
}
.add-new-project-modal .ant-modal-content .map-img > div {
  border-radius: 24px;
}
.add-new-project-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  margin-bottom: 24px;
}
.add-new-project-modal .ant-modal-content .ant-modal-body {
  flex-direction: row;
  align-items: flex-start;
}
.add-new-project-modal .ant-modal-content .ant-modal-body .project-input-fields .workspace-title {
  margin-bottom: 24px;
  font: var(--body-large-bold);
  color: #010101;
  margin-top: 0;
}
.upload-video .ant-upload-select {
  display: block;
}
.upload-video .upload-video-preview {
  column-gap: 12px;
}
.upload-video .upload-video-preview .upload-video-preview-img {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px #d2d2e1 solid;
}
.upload-video .upload-video-preview .upload-video-preview-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.upload-video .upload-video-preview .upload-video-preview-name {
  font: var(--body-default-regular);
  color: #010101;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.upload-video .upload-video-preview .upload-video-preview-time {
  font: var(--body-default-regular);
  color: #514f55;
}
.entire-synced {
  position: relative;
  top: -40px;
}
.remove-member .ant-dropdown-menu-root {
  border-radius: 8px;
  border: 1px solid #d2d2e1;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(81, 79, 85, 0.12);
  font: var(--body-small-regular);
}
.remove-member .ant-dropdown-menu-root .ant-dropdown-menu-item {
  color: #6c6f74;
}
.custom-input-autocomplete {
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}
.change-pw-title {
  font: var(--body-default-medium);
}
.change-pw {
  font: var(--body-meta-regular);
  color: #28a864;
  text-decoration-line: underline;
  cursor: pointer;
}
.delete-account-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: underline;
  color: #e64e2c;
  cursor: pointer;
  font: var(--body-meta-regular);
}
.google-text {
  font-weight: 600;
}
.title {
  font-size: 20px;
}
.main-wrapper {
  padding: 16px 0;
  column-gap: 32px;
}
.main-wrapper .terramatic-logo {
  margin-left: -32px;
}
.main-wrapper .video-player {
  display: flex;
  flex-direction: column;
}
.main-wrapper .video-player .react-player {
  position: relative;
}
.main-wrapper .video-player .react-player .seek-play-pause {
  width: 80%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.main-wrapper .video-player .react-player .seek-play-pause button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(42, 40, 40, 0.3);
  border: none;
  color: white;
  font-size: 17px;
}
.main-wrapper .video-player .react-player:hover .seek-play-pause {
  opacity: 1;
}
.main-wrapper .video-player .react-player video {
  border-radius: 8px;
  border: 2px solid #d2d2e1;
}
.main-wrapper .video-player .react-player .ant-slider .ant-slider-rail {
  background: #cccccc;
}
.main-wrapper .video-player .react-player .ant-slider .ant-slider-track {
  background: #010101;
  height: 6px;
}
.main-wrapper .video-player .react-player .ant-slider .ant-slider-handle::after {
  box-shadow: none;
  background-color: #010101;
  width: 13px;
  height: 13px;
}
.main-wrapper .map-wrapper {
  justify-content: flex-end;
}
.main-wrapper .map-wrapper .common-map {
  z-index: 1000;
  height: 350px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  border: 2px solid #d2d2e1;
}
.main-wrapper .map-wrapper .map-dropdown {
  position: absolute;
  height: 34px;
  right: 54px;
  top: 15px;
  z-index: 2000;
  width: 150px;
}
.main-wrapper .map-wrapper .detection-map {
  height: 350px;
}
.main-wrapper .map-wrapper .map-container {
  height: 350px;
}
.video-slider .ant-slider {
  margin: -16px 0 8px 0;
  height: 38px;
  display: flex;
  align-items: center;
  background: #d4d6d9;
  border-radius: 4px;
  padding: 0 10px;
}
.video-slider .ant-slider .ant-slider-handle {
  margin-top: 12px;
  margin-left: 10px;
}
.video-slider .ant-slider .ant-slider-handle:focus::after {
  box-shadow: none;
}
.video-slider .ant-slider .ant-slider-handle::after {
  box-shadow: none;
  background-color: #010101;
  width: 13px;
  height: 13px;
}
.video-slider .ant-slider .ant-slider-track {
  margin-left: 10px;
  background: #010101;
  height: 6px;
}
.upload-kml {
  margin: 8px 0;
  justify-content: space-between;
  column-gap: 32px;
}
.upload-kml .upload-kml-div {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  font: var(--body-default-medium);
}
.upload-kml .upload-kml-div .kml-upload {
  color: #33aa44;
  cursor: pointer;
}
.upload-kml .upload-kml-div .disabled-kml-upload {
  color: #cccccc;
}
.upload-kml .upload-kml-div .discard {
  color: #999;
}
.upload-kml .upload-kml-div .save {
  color: #3a4;
}
.sync-top {
  border-bottom: 1px solid #e6e6f2;
  background: #ffffff;
}
.ant-table-cell .edit-dropdown {
  font: var(--body-small-regular);
  color: #33aa44;
  cursor: pointer;
}
.save-details {
  visibility: hidden;
}
.modal-components-design {
  top: 4px;
}
.modal-components-design .ant-modal-close {
  top: 32px;
}
.modal-components-design .custom-label .ant-form-item {
  margin-bottom: 0;
  margin-top: 24px;
}
.modal-components-design .custom-label .add-new-project-save-btn {
  margin-top: 24px;
}
.modal-components-design .custom-label.add-new-project-form > .ant-form-item {
  margin-bottom: 0;
  margin-top: 16px;
}
.modal-components-design .custom-label.add-new-project-form > .ant-form-item.project-address .ant-form-item-label label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-components-design .custom-label.add-new-project-form > .ant-form-item.project-address.report-header {
  margin-top: 32px;
}
.modal-components-design .custom-label.add-new-project-form > .ant-form-item:last-child {
  margin-top: 24px;
}
.modal-components-design .custom-label.add-new-project-form .ant-row .ant-form-item {
  margin-bottom: 0;
  margin-top: 16px;
}
.modal-components-design .custom-label.add-new-project-form .ant-form-item-label label {
  display: flex;
  flex-direction: unset;
}
.modal-components-design .custom-label.add-new-project-form .ant-form-item-label label::before {
  order: 2;
  flex: 1;
}
.modal-components-design .custom-label.add-new-project-form .ant-form-item-label label .ant-form-item-tooltip {
  order: 3;
}
.modal-components-design .custom-label.add-new-project-form .ant-form-item-label label .ant-form-item-tooltip.pointer {
  cursor: pointer;
}
.modal-components-design .custom-label.setting .project-logo {
  margin-bottom: 0;
  margin-top: 0;
}
.modal-components-design .custom-label.setting .ant-divider {
  border: 1px dashed #d2d2e1;
}
.modal-components-design .ant-modal-content {
  padding: 32px;
  border: 1px solid #d2d2e1;
  border-radius: 24px;
}
.modal-components-design .ant-modal-content .ant-modal-title {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font: var(--body-large-bold);
  color: #010101;
  margin-bottom: 32px;
}
.modal-components-design .ant-modal-content .ant-modal-body {
  padding: 0;
}
.modal-components-design .ant-modal-content .ant-modal-body .ant-typography {
  font: var(--body-default-regular);
  color: #6c6f74;
}
.modal-components-design .ant-modal-content .ant-modal-header {
  padding: 0;
  border-bottom: transparent;
}
.modal-components-design .workspace-agency-details {
  display: flex;
  column-gap: 12px;
}
.modal-components-design .workspace-agency-details img {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}
.modal-components-design .workspace-agency-details .logo-img {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}
.modal-components-design .workspace-agency-logo {
  font: var(--body-default-regular);
  color: #514f55;
}
.modal-components-design .preferable-image-text {
  font: var(--body-regular-base);
  color: #999999;
  margin: 4px 0 0;
}
.modal-components-design .edit-delete-btn {
  margin-top: 6px;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.assign-member-modal .ant-modal-content {
  min-height: 425px;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .ant-btn-primary {
  height: 48px;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .ant-form {
  display: flex;
  gap: 8px;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .ant-form .ant-form-item {
  margin: 0;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .ant-form .ant-form-item:first-child {
  flex: 1;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .ant-form .ant-form-item .ant-form-item-control-input {
  min-height: 48px;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .ant-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content {
  height: 100%;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .ant-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input-group-wrapper {
  height: 100%;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .ant-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input-group-wrapper .ant-input-wrapper {
  height: 100%;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .ant-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input-group-wrapper .ant-input-wrapper .ant-input {
  height: 100%;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .ant-form .ant-form-item .primary-main-btn {
  width: 100%;
  height: auto;
  padding: 13px 16px;
  border: 0;
  background: #33aa44;
  border-radius: 6px;
  font: var(--body-small-medium);
  box-shadow: none;
  color: #ffffff;
}
.assign-member-modal .ant-modal-content .ant-modal-body .assign-by-email .assign-member-text {
  color: #e64e2c;
  font: var(--body-meta-regular);
  margin: 8px 0 0 0;
}
.assign-member-modal .ant-modal-content .ant-modal-body .dashed {
  border-top: 2px dashed #d2d2e1;
  border-bottom: 0;
  margin: 32px 0;
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing p {
  font: var(--body-small-regular);
  color: #999999;
  margin-bottom: 12px;
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing .list-scroll {
  max-height: 400px;
  overflow: auto;
  padding-right: 5px;
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing .list-scroll .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 16px;
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing .list-scroll .list .user {
  display: flex;
  align-items: center;
  gap: 10px;
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing .list-scroll .list .user .default-image {
  border: 1px solid #d2d2e1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f4f7;
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing .list-scroll .list .user .email {
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font: var(--body-default-regular);
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing .list-scroll .list .roles {
  color: #6c6f74;
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing .list-scroll .list .roles.owner {
  padding-right: 64px;
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing .list-scroll .list .ant-select .ant-select-selector {
  border: none;
  box-shadow: none;
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing .list-scroll .list .ant-select .ant-select-selector .ant-select-selection-item {
  color: #6c6f74;
}
.assign-member-modal .ant-modal-content .ant-modal-body .members-listing .list-scroll .list .delete-icon {
  border-radius: 20px;
  padding: 8px;
  border: 1px solid #d2d2e1;
  display: flex;
}
.sync-table-wrapper {
  margin-top: 16px;
  position: relative;
}
.sync-table-wrapper .button-wraper {
  display: flex;
  gap: 16px;
  position: absolute;
  right: 0;
  z-index: 99;
}
.sync-table-wrapper .ant-tabs-top .ant-tabs-nav {
  margin-bottom: 0px;
}
.sync-table-wrapper .ant-tabs-top .ant-tabs-nav::before {
  border-bottom: 0px;
}
.sync-table-wrapper .ant-tabs-top .ant-tabs-nav .ant-tabs-tab {
  border-top: 1px solid #d2d2e1;
  border-right: 1px solid #d2d2e1;
  border-left: 1px solid #d2d2e1;
}
.sync-table-wrapper .ant-tabs-top .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #f1f1f1;
  font: var(--body-meta-bold);
}
.sync-table-wrapper .ant-tabs-top .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #514f55;
}
.sync-table-wrapper .ant-tabs-top .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-active) {
  background-color: #ffffff;
  font: var(--body-small-regular);
}
.sync-table-wrapper .ant-tabs-top .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-active) .ant-tabs-tab-btn {
  color: #6c6f74;
}
.sync-table-wrapper .ant-tabs-tab {
  padding: 12px 36px !important;
  border-bottom: 0px !important;
}
.sync-table-wrapper .custom-table .ant-table {
  border-top-left-radius: 0px;
}
.sync-table-wrapper .custom-table .ant-table .ant-table-container .ant-table-thead tr .ant-table-cell:first-child {
  border-top-left-radius: 0px;
}
.sync-table-wrapper .reset-filters {
  position: absolute;
  right: 0;
  z-index: 99;
}
.video-name-search {
  padding: 10px;
  min-width: 260px;
  max-width: 260px;
}
.video-name-search .ant-input {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 2px;
}
.video-name-search .ant-space {
  width: 100%;
}
.video-name-search .ant-space .ant-space-item {
  flex: 1;
}
.video-name-search .ant-space .ant-space-item .ant-btn {
  box-shadow: none;
  border-radius: 2px;
  width: 100%;
}
.video-name-search .ant-space .ant-space-item .ant-btn.secondary-btn {
  border: 1px solid #d9d9d9;
}
.add-new-detection {
  height: 100%;
  margin-bottom: -24px;
}
.add-new-detection .add-detection-back-button {
  display: flex;
  align-items: center;
  font: var(--body-small-regular);
}
.add-new-detection .sidebar .ant-checkbox-wrapper {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.add-new-detection .sidebar .ant-checkbox-wrapper .ant-checkbox + span {
  color: #111111;
  font: var(--body-regular-base);
}
.add-new-detection .sidebar .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #333333;
  border-color: #333333;
}
.add-new-detection .add-detection-img-title {
  font-size: 22px;
  font-weight: 500;
  line-height: 25.81px;
  margin: 32px 0;
}
.add-new-detection .annotate-image {
  width: 100%;
}
.add-new-detection .sidebar {
  padding-right: 32px;
  border-right: 1px solid #d2d2e1;
}
.add-new-detection .sidebar .annotate-button {
  gap: 8px;
  flex-wrap: wrap;
}
.add-new-detection .sidebar .annotate-button .ant-upload-wrapper {
  flex: 1;
  min-width: 145px;
}
.add-new-detection .sidebar .annotate-button .ant-upload-wrapper .ant-upload {
  width: 100%;
}
.add-new-detection .sidebar .annotate-button .ant-btn {
  flex: 1;
  min-width: 145px;
  width: 100%;
}
.add-new-detection .sidebar .sidebar-sticky {
  position: sticky;
  top: 0;
}
.add-new-detection .sidebar .sidebar-sticky .dashed-separator {
  height: 1px;
  margin: 24px 0;
  border: 1px dashed #d2d2e1;
}
.add-new-detection .sidebar .sidebar-sticky .ai-powered-info {
  border-radius: 7px;
  border: 1px solid #d2d2e1;
  padding: 12px;
  background: #f1f1f1;
}
.add-new-detection .sidebar .sidebar-sticky .ai-powered-info .ai-powered-title {
  font: var(--body-medium-extra-large);
  color: #035fc4;
}
.add-new-detection .sidebar .sidebar-sticky .ai-powered-info .ai-powered-description {
  color: #514f55;
  font: var(--body-reg-medium);
}
.add-new-detection .form-section {
  margin: 32px 0 0 32px;
}
.add-new-detection .form-section .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-new-detection .form-section .header .title {
  font: var(--body-large-medium);
}
.add-new-detection .form-section .form {
  display: block;
  padding-top: 16px;
}
.add-new-detection .form-section .form .border-dashed {
  border: 1px dashed #d2d2e1;
}
.add-new-detection .form-section .form .ant-btn-link {
  font: var(--body-default-regular);
}
.add-new-detection .form-section .form .delete-detection.ant-btn {
  min-width: auto;
  border-color: #e64e2c;
}
.add-new-detection .form-section .form .property-section .ant-table-wrapper .ant-table-tbody .ant-table-cell .property-name .text {
  max-width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-new-detection .form-section .form .property-section .ant-table-wrapper .ant-table-tbody .ant-table-cell .recommendations {
  display: flex;
  align-items: center;
}
.add-new-detection .form-section .form .property-section .ant-table-wrapper .ant-table-tbody .ant-table-cell .recommendations .description {
  max-width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-new-detection .form-section .form .property-section .ant-table-wrapper .ant-table-tbody .ant-table-cell .recommendations .description-count {
  margin-left: 4px;
  font-size: 12px;
  background: #f1f1f1;
  border: 1px solid #d2d2e1;
  padding: 6px 8px 6px 8px;
  border-radius: 50%;
  flex-shrink: 0;
}
.add-new-detection .form-section .form .property-section .ant-table-wrapper .ant-table-tbody .ant-table-cell .actions {
  color: #008440;
}
.add-new-detection .form-section .form .property-section .detection-details-scroll {
  height: 40vh;
  overflow: auto;
  padding-bottom: 10px;
}
.add-new-detection .form-section .form .property-section .detection-details-scroll .detection-property-list-img {
  width: 25px;
  height: 24px;
  border-radius: 2px;
}
.add-new-detection .ant-spin-nested-loading {
  height: 100%;
}
.add-new-detection .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}
.add-new-detection .ant-spin-nested-loading .ant-spin-container .ant-row {
  height: 100%;
}
.property-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  margin-bottom: 24px;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow {
  max-height: 464px;
  overflow: auto;
  padding-right: 10px;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item {
  margin-top: 24px;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item:first-child {
  margin-top: 0;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item.other-property-input {
  margin-top: 8px;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item .ant-checkbox-group {
  width: 100%;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item .ant-checkbox-group .ant-checkbox-wrapper {
  width: 100%;
  padding: 8px 0;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span:last-child textarea {
  color: #263a30;
  box-shadow: 0px 0px 6px 0px #00844066;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item .ant-checkbox-group .ant-checkbox-wrapper .ant-input-disabled {
  background-color: transparent;
  color: #6c6f74;
  cursor: initial;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item .ant-checkbox-group .ant-checkbox-wrapper span:last-child {
  flex: 1;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item.other-recommendation {
  margin-top: 0;
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item.recommendation-required .ant-form-item-label label::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.property-modal .ant-modal-content .ant-modal-body .form-overflow .ant-form-item .ant-form-item-control-input .standard-value {
  font: var(--body-regular-base);
  color: #999999;
  margin: 8px 0 0;
}
.comment-drawer .ant-drawer-body {
  padding: 12px 24px 12px 16px;
  display: flex;
  flex-direction: column;
}
.comment-drawer .ant-drawer-body .detection-id {
  margin-bottom: 24px;
  font: var(--body-small-medium);
  margin-top: 0;
  color: #999999;
}
.comment-drawer .ant-drawer-body .detection-id b {
  color: #010101;
  font: var(--body-medium-base);
}
.comment-drawer .ant-drawer-body .comment-box {
  display: flex;
  gap: 12px;
}
.comment-drawer .ant-drawer-body .comment-box .comment-form {
  flex: 1;
}
.comment-drawer .ant-drawer-body .comment-box .comment-form .text-box {
  margin-bottom: 6px;
}
.comment-drawer .ant-drawer-body .border-dashed {
  border: 1px dashed #d2d2e1;
  margin: 24px 0;
}
.comment-drawer .ant-drawer-body .no-comment {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
}
.comment-drawer .ant-drawer-body .comment-overflow {
  flex: 1;
  overflow: auto;
}
.comment-drawer .ant-drawer-body .comment-overflow .comment-list .comment {
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #f1f1f1;
  display: flex;
  gap: 12px;
  padding: 16px;
}
.comment-drawer .ant-drawer-body .comment-overflow .comment-list .comment .comment-form {
  flex: 1;
}
.comment-drawer .ant-drawer-body .comment-overflow .comment-list .comment .comment-form .comment-form-comment {
  font: var(--body-default-regular);
  color: #010101;
  margin: 0;
  word-break: break-all;
}
.comment-drawer .ant-drawer-body .comment-overflow .comment-list .comment .comment-form .comment-form-sub-name {
  font: var(--body-medium-base);
  color: #010101;
}
.comment-drawer .ant-drawer-body .comment-overflow .comment-list .comment .comment-form .comment-time {
  font: var(--body-regular-base);
  color: #999999;
}
.comment-drawer .ant-drawer-body .ant-spin-nested-loading {
  flex: 1;
  overflow: auto;
}
.comment-drawer .ant-drawer-body .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}
.property-actions .ant-dropdown-menu {
  padding: 8px 12px;
}
.property-actions .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 8px 4px;
}
.property-actions .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled) {
  color: #e64e2c;
}
.property-actions .ant-dropdown-menu .ant-dropdown-menu-item.error-color {
  color: #e64e2c;
}
.property-actions .ant-dropdown-menu .ant-dropdown-menu-item-divider {
  margin: 4px;
}
.project-card-dropdown.ant-dropdown-placement-bottom {
  position: fixed;
  top: 20px;
  right: 30px;
}
.project-card-dropdown .ant-dropdown-menu {
  padding: 8px 8px;
  min-width: 180px;
}
.project-card-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-divider {
  background-color: #d2d2e1;
  margin: 4px;
}
.project-card-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 8px 8px;
}
.project-card-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.error-color {
  color: #e64e2c;
}
.consolidated-view .consolidated-view-map {
  height: calc(100vh - 160px);
}
.consolidated-view .consolidated-view-map .google-map-div {
  position: relative;
}
.consolidated-view .consolidated-view-map .total-distance-covered {
  position: absolute;
  top: 50px;
  right: 50px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #d2d2e1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 16px 16px;
  font: var(--body-default-medium);
  color: #008440;
}
.consolidated-view .consolidated-view-content {
  height: 100%;
  overflow: auto;
}
.consolidated-view .synced-video-count {
  font: var(--body-extra-large);
  color: #010101;
}
.consolidated-view .sync-videos {
  border-radius: 8px;
  border: 1px solid #d2d2e1;
  background-color: #f1f1f1;
}
.consolidated-view .sync-videos .sync-video-duration {
  border: 1px solid #d2d2e1;
  border-radius: 1000px;
  padding: 6px 12px;
  background: #ffffff;
  max-height: 29px;
}
.consolidated-view .sync-videos .sync-video-title {
  font: var(--body-bold-large);
  color: #111111;
}
.consolidated-view .sync-videos .sync-video-distance {
  font: var(--body-medium-base);
  color: #333;
}
.consolidated-view .sync-videos .sync-video-chainage-title {
  font: var(--body-regular-base);
  color: #999999;
}
.consolidated-view .sync-videos .sync-video-chainage {
  font: var(--body-reg-medium);
  color: #010101;
}
.reports-title {
  font: var(--body-bold-large);
  color: #263a30;
}
.reports-description p {
  font: var(--body-reg-medium);
  color: #6c6f74;
  margin: 0;
}
.reportsInview {
  overflow: auto;
  height: calc(100vh - 200px);
}
.analysis-title {
  font: var(--body-large-medium);
  color: #263a30;
}
.traffic-analysis {
  border-radius: 12px;
  border: 1px #d2d2e1 solid;
  background: #f4f6f8;
  padding: 16px;
}
.traffic-analysis .steps-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;
  height: 100%;
}
.traffic-analysis .steps-block .ant-form-item {
  margin-bottom: 0;
}
.traffic-analysis .steps-block .steps-footer .ant-btn-disabled {
  border-color: transparent;
}
.traffic-analysis .steps-block .steps-footer .ant-btn-disabled .ant-btn-icon svg path {
  stroke: #ffffff;
}
.traffic-analysis .steps-block .time-duration .ant-form-item .ant-picker {
  width: 100%;
  height: 48px;
}
.traffic-analysis .step {
  font: var(--body-bold-medium);
  color: #263a30;
}
.traffic-analysis .description {
  font: var(--body-reg-medium);
  color: #6c6f74;
}
.traffic-analysis .csv-file-name {
  font: var(--body-bold-medium);
}
.time-picker .ant-picker-time-panel-column::after {
  display: none;
}
.time-picker .ant-picker-footer .ant-picker-ok .ant-btn {
  min-width: auto;
}
.time-picker .ant-picker-footer .ant-picker-ranges {
  margin: 0;
  gap: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
div[aria-roledescription='map'] div:nth-child(4) div:first-child .gm-style-iw-a {
  display: none;
}
.image-editor-modal {
  font-family: var(--first-font);
}
.image-editor-modal * {
  font-family: inherit !important;
}
.image-editor-modal *[color='primary'] {
  background-color: #008440;
}
.image-editor-modal *[color='primary']:hover {
  background-color: #54b860;
}
.image-editor-modal .ant-modal-content .ant-modal-body {
  height: 85vh;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs {
  padding: 0;
  box-shadow: none;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs *[aria-selected='true'] {
  background-color: #d8f3dc !important;
  border: 1px solid #008440;
  color: #008440 !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs *[aria-selected='true'] * {
  color: inherit !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs *[aria-selected='false'] {
  background-color: transparent !important;
  border: 1px solid #d2d2e1;
  color: #6c6f74 !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs *[aria-selected='false'] * {
  color: inherit !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs .FIE_tab {
  padding: 10px;
  width: 112px !important;
  height: 110px !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs .FIE_tab * {
  font: var(--body-bold-medium) !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs .FIE_tab:first-child .SfxLabel-text {
  font-size: 0 !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs .FIE_tab:first-child .SfxLabel-text::after {
  content: 'Annotate';
  font: var(--body-bold-medium) !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs .FIE_tab:nth-child(2) .SfxLabel-text {
  font-size: 0 !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_tabs .FIE_tab:nth-child(2) .SfxLabel-text::after {
  content: 'Draw';
  font: var(--body-bold-medium) !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_editor-content {
  width: calc(100% - 112px);
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_editor-content .FIE_tools-bar-wrapper {
  max-height: unset;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_editor-content .FIE_tools-bar-wrapper .FIE_tools-bar *[aria-selected='true'] {
  background-color: #d8f3dc !important;
  border: 1px solid #008440;
  color: #008440 !important;
  border-radius: 6px;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_editor-content .FIE_tools-bar-wrapper .FIE_tools-bar *[aria-selected='true'] * {
  color: inherit !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_editor-content .FIE_tools-bar-wrapper .FIE_tools-bar *[aria-selected='false'] {
  background-color: transparent !important;
  color: #6c6f74 !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_editor-content .FIE_tools-bar-wrapper .FIE_tools-bar *[aria-selected='false'] * {
  color: inherit !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_editor-content .FIE_tools-bar-wrapper .FIE_tools-bar .FIE_carousel-item .FIE_watermark-selected-item {
  height: 84px;
  width: 84px;
  text-align: center;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_editor-content .FIE_tools-bar-wrapper .FIE_tools-bar .FIE_watermark-add-wrapper button {
  display: none;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_topbar {
  padding: 24px 0 !important;
  border-bottom: 0;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_topbar .FIE_topbar-history-buttons button {
  height: 24px;
  width: 24px;
  margin: 0 8px;
  padding: 4px;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_topbar .FIE_topbar-history-buttons button svg {
  width: 100%;
  height: 100%;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_topbar .FIE_topbar-history-buttons button.reset-button {
  height: 40px;
  width: auto;
  border-radius: 6px;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_topbar .FIE_topbar-save-wrapper {
  width: auto !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_topbar .FIE_topbar-save-wrapper .FIE_topbar-save-button {
  height: 40px;
  border-radius: 6px;
  padding: 12px !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_topbar .FIE_topbar-save-wrapper .FIE_topbar-save-button span {
  font: var(--body-bold-medium) !important;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_topbar .FIE_topbar-save-wrapper .FIE_topbar-save-button > span:after {
  content: '& Continue';
  margin-left: 4px;
}
.image-editor-modal .ant-modal-content .ant-modal-body .reset-image {
  position: absolute;
  top: 54px;
  right: 174px;
  font-size: 20px;
}
.image-editor-modal .ant-modal-content .ant-modal-body .reset-image svg {
  fill: #768a9f;
}
.image-editor-modal .ant-modal-content .ant-modal-body .FIE_spinner::after {
  border-color: #008440 transparent !important;
}
.image-editor-modal .ant-modal-close {
  inset-inline-end: 24px;
}
.SfxModal-Wrapper * {
  font-family: var(--first-font) !important;
}
.SfxModal-Wrapper .SfxModal-Container {
  border-radius: 16px;
  max-width: 400px !important;
}
.SfxModal-Wrapper .SfxModal-Container .SfxModalTitle-Icon svg path {
  fill: rgba(0, 0, 0, 0.25);
}
.SfxModal-Wrapper .SfxModal-Container .SfxModalTitle-LabelPrimary {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.88);
}
.SfxModal-Wrapper .SfxModal-Container .SfxModalTitle-LabelSecondary {
  margin: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6c6f74;
  text-align: center;
}
.SfxModal-Wrapper .SfxModal-Container .SfxModalActions-root .SfxButton-root {
  height: 40px;
  border-radius: 6px;
  padding: 12px;
}
.SfxModal-Wrapper .SfxModal-Container .SfxModalActions-root .SfxButton-root:not(:first-child) {
  background-color: #008440 !important;
}
.SfxModal-Wrapper .SfxModal-Container .SfxModalActions-root .SfxButton-root:not(:first-child):hover {
  background-color: #54b860 !important;
}
.graph-container {
  display: flex;
  justify-content: center;
  background: #ffffff;
  border-radius: 24px;
  border: 2px #d2d2e1 solid;
}
.report-list .ant-table .ant-table-container .ant-table-content .ant-table-tbody .ant-table-cell .generating-report {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 95px;
}
.report-list .ant-table .ant-table-container .ant-table-content .ant-table-tbody .ant-table-cell .ant-btn {
  width: 200px;
  border-radius: 6px;
  border: 1.5px solid #263a30;
  background: transparent;
}
.report-list .ant-table .ant-table-container .ant-table-content .ant-table-tbody .ant-table-cell .ant-btn.generating {
  border: 1px solid #cccccc;
}
.report-list .ant-table .ant-table-container .ant-table-content .ant-table-tbody .ant-table-cell .ant-btn.generating span {
  color: #cccccc;
}
.report-list .ant-table .ant-table-container .ant-table-content .ant-table-tbody .ant-table-cell .ant-btn.failed {
  border: 1px solid #e64e2c;
}
.report-list .ant-table .ant-table-container .ant-table-content .ant-table-tbody .ant-table-cell .ant-btn.failed span {
  color: #e64e2c;
}
.report-list .ant-table .ant-table-container .ant-table-content .ant-table-tbody .ant-table-cell .ant-btn span {
  font: var(--body-default-bold);
}
.report-list .ant-table .ant-table-container .ant-table-content .ant-table-tbody .ant-table-cell:last-child {
  text-align: right;
}
.uploaded_csv_error {
  font: var(--body-regular-base);
  color: #e64e2c;
}
.change-password .ant-modal-content {
  border-radius: 24px;
}
.change-password .ant-modal-content .ant-modal-header {
  margin-bottom: 32px;
}
.change-password .ant-modal-content .ant-modal-header .ant-modal-title {
  font: var(--body-large-bold);
}
.change-password .ant-modal-content .ant-modal-body .ant-form-item.submit-button {
  padding-top: 8px;
}
.change-password .ant-modal-content .ant-modal-body .ant-form-item .ant-form-item-label label {
  flex-direction: row-reverse;
}
.account-delete-modal .ant-modal-body .ant-form-item .ant-form-item-label label {
  flex-direction: row-reverse;
}
.account-delete-modal .ant-modal-body .ant-divider {
  border: 1px dashed #d2d2e1;
}
.detection-type-filter {
  padding: 4px 16px;
}
.detection-type-filter .ant-space .ant-space-item {
  border-bottom: 1px solid #d2d2e1;
  padding: 8px 0;
}
.detection-type-filter .ant-space .ant-space-item:last-child {
  border-bottom: none;
}
.ant-table-filter-dropdown .see-all-menu-filter {
  padding: 8px 8px;
}
.ant-table-filter-dropdown .see-all-menu-filter .ant-dropdown-menu-item {
  padding: 4px 4px;
}
.ant-table-filter-dropdown .see-all-menu-filter .ant-dropdown-menu-item-divider {
  margin: 4px 4px;
  background: #d2d2e1;
}
.ant-table-filter-dropdown .checkbox-filter-wrapper {
  padding: 12px 16px;
  max-height: 250px;
  max-width: 250px;
  overflow: auto;
}
.ant-table-filter-dropdown .checkbox-filter-wrapper .ant-space {
  width: 100%;
}
.ant-table-filter-dropdown .checkbox-filter-wrapper .ant-space .ant-space-item .ant-divider {
  margin: 8px 0;
}
.ant-table-filter-dropdown .checkbox-filter-wrapper .ant-space .ant-space-item .ant-checkbox-wrapper .checkbox-text {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-filter-dropdown .checkbox-filter-wrapper-btn-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  border-top: 1px solid #0000000f;
  padding: 8px;
}
.ant-table-filter-dropdown .checkbox-filter-wrapper-btn-wrapper .ant-btn {
  min-width: auto;
}
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #008440;
  color: #008440;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  text-align: right;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #008440;
  color: #008440;
  left: -10px;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #008440;
  color: #008440;
}
@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
.start-end-icon {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 0;
  top: -30px;
}
.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.magic-blue {
  display: flex;
  column-gap: 8px;
}
.video-wrapper {
  position: relative;
  max-width: 1080;
}
.video-wrapper .video {
  height: auto;
  width: 100%;
}
.video-wrapper .ai-processing-div {
  display: flex;
  column-gap: 13px;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 10px;
  margin: 24px;
}
.video-wrapper .ai-processing-div .magic-pen {
  background: black;
  padding: 10px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #035fc4;
}
.video-wrapper .ai-processing-div .ant-switch {
  background: #f1f1f1;
  border: 1px #d2d2e1 solid;
}
.video-wrapper .ai-processing-div .ant-switch.ant-switch-checked {
  background-color: #d9ebfe;
  border: 1px #96c7fd solid;
}
.video-wrapper .ai-processing-div .ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 30px);
}
.video-wrapper .ai-processing-div .ant-switch.ant-switch-checked .ant-switch-handle:before {
  background: #035fc4;
  border-radius: 50%;
}
.video-wrapper .ai-processing-div .ant-switch .ant-switch-handle {
  width: 32px;
  height: 32px;
  top: -5px;
  inset-inline-start: -2px;
}
.video-wrapper .ai-processing-div .ant-switch .ant-switch-handle:before {
  background: #999999;
  border-radius: 50%;
}
.video-wrapper .ai-processing-div .ant-switch .ant-switch-inner {
  width: 56px;
  height: 24px;
}
.view-details-ai-process {
  display: flex;
  align-items: center;
}
.view-details-ai-process .ai-processing-title-terminated {
  color: #e64e2c;
  font: var(--body-bold-large);
}
.view-details-ai-process .ai-processing-title-completed {
  color: #035fc4;
  font: var(--body-bold-large);
}
.sync-container .ant-typography {
  font: var(--body-default-medium);
}
.sync-container .processing-completed .ant-typography {
  color: #3bb665;
}
.sync-container .processing-in_progress .ant-typography {
  color: #daae15;
}
.sync-container .processing-terminating .ant-typography {
  color: #daae15;
}
.sync-container .processing-terminated .ant-typography {
  color: #6c6f74;
}
.sync-container .ai-processing-status {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.sync-container .separate {
  width: 1px;
  height: 20px;
  background: #d2d1e1;
}
.video-processing-status .status-in-progress,
.image-processing-status .status-in-progress {
  display: flex;
  align-items: center;
  column-gap: 4px;
  border-radius: 6px;
  padding: 4px 12px;
  border: 1.5px solid #daae15;
  color: #daae15;
  font: var(--body-medium-base);
}
.video-processing-status .status-terminated,
.image-processing-status .status-terminated {
  color: #008440;
  font: var(--body-regular-base);
}
.video-processing-status .status-completed,
.image-processing-status .status-completed {
  display: flex;
  align-items: center;
  column-gap: 4px;
  border-radius: 6px;
  padding: 4px 12px;
  border: 1.5px solid #28a864;
  color: #28a864;
  font: var(--body-medium-base);
}
.video-processing-status .image-status-in-progress,
.image-processing-status .image-status-in-progress {
  display: flex;
  align-items: center;
  column-gap: 4px;
  border-radius: 6px;
  padding: 4px 12px;
  border: 1.5px solid #daae15;
  color: #daae15;
  font: var(--body-medium-base);
  background: #fcf6e1;
}
.highlighted-row {
  background-color: #f4f6f8;
}
.FIE_canvas-container .FIE_canvas-node {
  background: #ffffff;
}
.color-wrapper {
  display: flex;
  align-items: center;
  column-gap: 4px;
}
.color-wrapper .btn-hollow {
  height: 20px;
  width: 20px;
  background: transparent;
  border: 1px solid #ccd6de;
  border-radius: 4px;
  padding: 0;
  margin: 6px;
  cursor: pointer;
}
.FIE_annotations-options span {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.FIE_annotations-options span .FIE_color-picker-triggerer {
  height: 20px;
  width: 20px;
  margin: 6px;
}
.FIE_annotations-options .FIE_color-picker-triggerer {
  height: 20px;
  width: 20px;
  margin: 6px;
}
.FIE_spinner-wrapper svg path {
  fill: #33aa44;
}
.ant-layout-content .solid,
.container .solid,
.custom-label .solid,
.workspace-agency-details .solid,
.workspace-details-form .solid,
.sync-container .btn-component .solid,
.btn-component .solid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  height: 38px;
  gap: 6px;
  background: #333333;
  min-width: 80px;
  border-radius: 6px;
  color: #ffffff;
  box-shadow: none;
  border-color: transparent;
  font: var(--body-small-medium);
}
.ant-layout-content .solid:hover,
.container .solid:hover,
.custom-label .solid:hover,
.workspace-agency-details .solid:hover,
.workspace-details-form .solid:hover,
.sync-container .btn-component .solid:hover,
.btn-component .solid:hover {
  color: #ffffff;
  border-color: #ffffff;
}
.ant-layout-content .solid:disabled,
.container .solid:disabled,
.custom-label .solid:disabled,
.workspace-agency-details .solid:disabled,
.workspace-details-form .solid:disabled,
.sync-container .btn-component .solid:disabled,
.btn-component .solid:disabled {
  background-color: #cccccc;
  color: #ffffff;
}
.ant-layout-content .outlined,
.container .outlined,
.custom-label .outlined,
.workspace-agency-details .outlined,
.workspace-details-form .outlined,
.sync-container .btn-component .outlined,
.btn-component .outlined {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  height: 38px;
  gap: 6px;
  justify-content: center;
  min-width: 80px;
  background: transparent;
  border-radius: 6px;
  color: #333333;
  box-shadow: none;
  border: 1px #333333 solid;
  font: var(--body-small-medium);
}
.ant-layout-content .outlined:hover,
.container .outlined:hover,
.custom-label .outlined:hover,
.workspace-agency-details .outlined:hover,
.workspace-details-form .outlined:hover,
.sync-container .btn-component .outlined:hover,
.btn-component .outlined:hover {
  color: #333333;
  border-color: #333333;
}
.ant-layout-content .outlined:disabled,
.container .outlined:disabled,
.custom-label .outlined:disabled,
.workspace-agency-details .outlined:disabled,
.workspace-details-form .outlined:disabled,
.sync-container .btn-component .outlined:disabled,
.btn-component .outlined:disabled {
  background-color: #cccccc;
  color: #333333;
}
.ant-layout-content .primary-outlined,
.container .primary-outlined,
.custom-label .primary-outlined,
.workspace-agency-details .primary-outlined,
.workspace-details-form .primary-outlined,
.sync-container .btn-component .primary-outlined,
.btn-component .primary-outlined {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  height: 38px;
  gap: 6px;
  background: transparent;
  border-radius: 6px;
  color: #33aa44;
  box-shadow: none;
  border: 1px #33aa44 solid;
  font: var(--body-small-medium);
}
.ant-layout-content .primary-outlined:hover,
.container .primary-outlined:hover,
.custom-label .primary-outlined:hover,
.workspace-agency-details .primary-outlined:hover,
.workspace-details-form .primary-outlined:hover,
.sync-container .btn-component .primary-outlined:hover,
.btn-component .primary-outlined:hover {
  color: #33aa44;
  border-color: #33aa44;
}
.ant-layout-content .primary-outlined:disabled,
.container .primary-outlined:disabled,
.custom-label .primary-outlined:disabled,
.workspace-agency-details .primary-outlined:disabled,
.workspace-details-form .primary-outlined:disabled,
.sync-container .btn-component .primary-outlined:disabled,
.btn-component .primary-outlined:disabled {
  background-color: #cccccc;
  color: #ffffff;
  border: none;
}
.ant-layout-content .primary-outlined.fill,
.container .primary-outlined.fill,
.custom-label .primary-outlined.fill,
.workspace-agency-details .primary-outlined.fill,
.workspace-details-form .primary-outlined.fill,
.sync-container .btn-component .primary-outlined.fill,
.btn-component .primary-outlined.fill {
  background-color: #33aa44;
  color: #ffffff;
}
.ant-layout-content .primary-main-btn,
.container .primary-main-btn,
.custom-label .primary-main-btn,
.workspace-agency-details .primary-main-btn,
.workspace-details-form .primary-main-btn,
.sync-container .btn-component .primary-main-btn,
.btn-component .primary-main-btn {
  width: 100%;
  height: 60px;
  align-items: center;
  background: #33aa44;
  border-radius: 12px;
  font: var(--body-large-bold);
  color: #ffffff;
}
.ant-layout-content .primary-main-btn:hover,
.container .primary-main-btn:hover,
.custom-label .primary-main-btn:hover,
.workspace-agency-details .primary-main-btn:hover,
.workspace-details-form .primary-main-btn:hover,
.sync-container .btn-component .primary-main-btn:hover,
.btn-component .primary-main-btn:hover {
  color: #ffffff;
}
.ant-layout-content .primary-main-btn:disabled,
.container .primary-main-btn:disabled,
.custom-label .primary-main-btn:disabled,
.workspace-agency-details .primary-main-btn:disabled,
.workspace-details-form .primary-main-btn:disabled,
.sync-container .btn-component .primary-main-btn:disabled,
.btn-component .primary-main-btn:disabled {
  background-color: #cccccc;
  color: #ffffff;
}
.ant-layout-content .primary-main-btn-small,
.container .primary-main-btn-small,
.custom-label .primary-main-btn-small,
.workspace-agency-details .primary-main-btn-small,
.workspace-details-form .primary-main-btn-small,
.sync-container .btn-component .primary-main-btn-small,
.btn-component .primary-main-btn-small {
  width: 100%;
  height: 60px;
  align-items: center;
  background: #33aa44;
  border-radius: 12px;
  font: var(--body-large-bold);
  color: #ffffff;
  width: 200px;
}
.ant-layout-content .primary-main-btn-small:hover,
.container .primary-main-btn-small:hover,
.custom-label .primary-main-btn-small:hover,
.workspace-agency-details .primary-main-btn-small:hover,
.workspace-details-form .primary-main-btn-small:hover,
.sync-container .btn-component .primary-main-btn-small:hover,
.btn-component .primary-main-btn-small:hover {
  color: #ffffff;
}
.ant-layout-content .primary-main-btn-small:disabled,
.container .primary-main-btn-small:disabled,
.custom-label .primary-main-btn-small:disabled,
.workspace-agency-details .primary-main-btn-small:disabled,
.workspace-details-form .primary-main-btn-small:disabled,
.sync-container .btn-component .primary-main-btn-small:disabled,
.btn-component .primary-main-btn-small:disabled {
  background-color: #cccccc;
  color: #ffffff;
}
.ant-layout-content .primary-main-outlined-btn,
.container .primary-main-outlined-btn,
.custom-label .primary-main-outlined-btn,
.workspace-agency-details .primary-main-outlined-btn,
.workspace-details-form .primary-main-outlined-btn,
.sync-container .btn-component .primary-main-outlined-btn,
.btn-component .primary-main-outlined-btn {
  width: 100%;
  height: 60px;
  align-items: center;
  border-radius: 12px;
  font: var(--body-large-bold);
  background: #33aa44;
  border-color: #33aa44;
  color: #ffffff;
}
.ant-layout-content .primary-main-outlined-btn:disabled,
.container .primary-main-outlined-btn:disabled,
.custom-label .primary-main-outlined-btn:disabled,
.workspace-agency-details .primary-main-outlined-btn:disabled,
.workspace-details-form .primary-main-outlined-btn:disabled,
.sync-container .btn-component .primary-main-outlined-btn:disabled,
.btn-component .primary-main-outlined-btn:disabled {
  background: #ffffff;
  color: #33aa44;
}
.dashed-button-upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 46px;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  font: var(--body-small-medium);
  color: #514f55;
  border: 1px #d2d2e1 dashed;
}
.dashed-button-upload:hover {
  color: #514f55;
  border: 1px #d2d2e1 dashed;
}
.modal-components-design {
  top: 4px;
}
.modal-components-design .ant-modal-close {
  top: 32px;
}
.modal-components-design .custom-label .ant-form-item {
  margin-bottom: 0;
}
.modal-components-design .custom-label .ant-form-item.addressLine2 {
  margin-top: 32px;
}
.modal-components-design .custom-label .add-new-project-save-btn {
  margin-top: 24px;
}
.modal-components-design .ant-modal-content {
  padding: 32px;
  border: 1px solid #d2d2e1;
  border-radius: 24px;
}
.modal-components-design .ant-modal-content .ant-modal-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  column-gap: 4px;
  font: var(--body-large-bold);
  color: #010101;
  margin-bottom: 32px;
}
.modal-components-design .workspace-agency-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  column-gap: 12px;
}
.modal-components-design .workspace-agency-details .logo-img {
  width: 100px;
  height: 100px;
  border-radius: 12px;
  object-fit: cover;
}
.modal-components-design .workspace-agency-logo {
  font: var(--body-default-regular);
  color: #514f55;
}
.modal-components-design .edit-delete-btn {
  margin-top: 6px;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 12px;
}
.modal-components-design .fileAttachment .ant-radio-wrapper .ant-radio + span {
  font: var(--body-default-medium);
  color: #1c1c1c;
  display: flex;
  align-items: center;
}
.modal-components-design .view-details-img {
  width: 100px;
  height: 100px;
  border-radius: 12px;
  border: 1px solid #d2d2e1;
}
.project-card-container {
  margin-top: 31px;
}
.project-card-container .ant-card .ant-card-cover img {
  border-radius: 16px 16px 0 0;
}
.project-card-container .ant-card .ant-card-body {
  height: 100px;
  overflow: hidden;
}
.project-card-container .ant-card .ant-card-actions > li {
  margin: 0;
  margin: 12px 0;
}
.project-card-container .project-card {
  min-height: 300px;
  border-radius: 16px;
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #d2d2e1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.project-card-container .project-card .ant-card-actions {
  background: #f4f6f8;
}
.project-card-container .project-card .ant-card-actions .card-redirect-click {
  display: block;
  width: 100%;
}
.project-card-container .project-card .ant-card-actions svg {
  height: 17px;
  width: 16px;
}
.project-card-container .project-card .ant-card-actions svg path {
  stroke: #6c6f74;
}
.project-card-container .project-card .ant-card-actions .dropdown span {
  width: 100%;
  justify-content: center;
}
.project-card-container .project-card .ant-card-meta-title {
  font: var(--body-default-bold);
  color: #514f55;
}
.project-card-container .project-card .ant-card-meta-description {
  font: var(--body-small-regular);
  color: #999999;
}
.project-card-container .project-card .card-cover {
  height: 150px;
}
.project-card-container .project-card .card-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.project-card-container .project-card .card-cover-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 28px;
  font-size: 32px;
  font-weight: 500;
  font-family: 'Space Grotesk';
  color: #ffffff;
  margin: 0;
}
.project-card-container .project-card .ant-card-body {
  padding: 0;
}
.project-card-container .project-card .ant-card-body .ant-card-meta {
  padding: 24px;
}
.custom-label .lat-lng {
  color: #999999;
  font: var(--body-small-regular);
  margin-top: 8px;
}
.custom-label .ant-input {
  color: #6c6f74;
  font: var(--body-default-regular);
}
.custom-label .ant-select-selection-item {
  color: #6c6f74;
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 400;
}
.custom-label .ant-input::placeholder {
  font: var(--body-default-regular);
  color: #514f55;
}
.custom-label .ant-select-selection-placeholder {
  font: var(--body-default-regular);
  color: #514f55;
}
.custom-label .ant-form-item-label label {
  font: var(--body-small-regular);
  color: #999999;
  flex-direction: row-reverse;
}
.custom-label .ant-form-item-explain-error {
  color: #e64e2c;
  font: var(--body-meta-regular);
}
.discard-modal .ant-modal-content {
  padding: 24px;
  border-radius: 16px;
}
.discard-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
.discard-modal .ant-modal-content .ant-modal-body p {
  margin: 16px 0 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6c6f74;
}
.discard-modal .ant-modal-content .ant-modal-footer {
  height: 40px;
}
.discard-modal .ant-modal-content .ant-modal-footer .ant-btn {
  min-width: 120px;
  padding: 8px 12px;
  height: inherit;
  box-shadow: none;
}
.discard-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-default {
  border: 1px #333333 solid;
}
.discard-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-default span {
  color: #333333;
  font: var(--body-default-bold);
}
.discard-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary {
  background-color: #263a30;
}
.discard-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary span {
  font: var(--body-default-bold);
}
.member-tags {
  min-width: 90px;
  text-align: center;
  padding: 6px 16px;
  border-radius: 20px;
  line-height: 16px;
}
.member-tags.accepted {
  color: #268033;
  border: 1px solid #89dc95;
}
.member-tags.invited {
  color: #4a4a4a;
  border: 1px solid #cccccc;
}
.member-tags.cancelled {
  color: #e64e2c;
  border: 1px solid #e64e2c;
}
.custom-table .ant-table {
  border-radius: 16px;
  border: 1px #d2d2e1 solid;
  overflow: hidden;
}
.custom-table .ant-table .ant-table-container .ant-btn {
  padding: 6px 16px;
  border-radius: 50%;
  font: var(--body-meta-medium);
  font-size: 12px;
}
.custom-table .ant-table .ant-table-container .ant-btn-primary {
  border: 1px solid #89dc95;
  background: #d8f3dc;
  color: #268033;
}
.custom-table .ant-table .ant-table-container .ant-btn-default {
  border: 1px solid #cccccc;
  background: #e6e6e6;
  color: #4a4a4a;
}
.custom-table .ant-table .ant-table-container table > thead > tr:first-child > *:last-child {
  border-start-end-radius: 16px;
}
.custom-table .ant-table .ant-table-container table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 16px;
}
.custom-table .ant-table .ant-table-container .ant-table-thead > tr > th {
  background: #f1f1f1;
  font: var(--body-medium-base);
  color: #6c6f74;
}
.custom-table .ant-table .ant-table-container .ant-table-thead > tr > th .ant-table-filter-trigger .anticon-search {
  color: #514f55;
}
.custom-table .ant-table .ant-table-container .ant-table-container > table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 16px;
}
.custom-table .ant-table .ant-table-container .ant-table-tbody {
  font: var(--body-regular-base);
  color: #514f55;
}
.custom-table-input-search {
  padding: 8px;
}
.custom-table-input-search .ant-space {
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
}
.custom-table-analysis .ant-table {
  border-radius: 8px;
  border: 1px #d2d2e1 solid;
}
.custom-table-analysis .ant-table .ant-table-container table > thead > tr:first-child > *:last-child {
  border-start-end-radius: 8px;
}
.custom-table-analysis .ant-table .ant-table-container table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 8px;
}
.custom-table-analysis .ant-table .ant-table-container .ant-table-content .ant-table-thead > tr > th {
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  font: var(--body-bold-large);
  color: #263a30;
}
.detection-slider {
  background-color: #d4d6d9;
  padding: 28px 22px 16px 22px;
  color: red;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.detection-slider .pause-icon {
  color: #000000;
}
.detection-slider .slider {
  width: 100%;
  margin-left: 16px;
}
.detection-slider .slider .ant-slider-rail {
  background-color: #f2f4f7;
  height: 8px;
}
.detection-slider .slider .ant-slider-track {
  background-color: #010101;
  height: 8px;
}
.detection-slider .slider .ant-slider-handle::after {
  background-color: #010101;
  box-shadow: 0 0 0 2px #000000;
  margin-top: 2px;
}
