/*breakpoint mixin start here*/
@screen1450: ~'only screen and (max-width: 1450px)';
@screen1150: ~'only screen and (max-width: 1150px)';
@screen1024: ~'only screen and (max-width: 1024px)';
@screen991: ~'only screen and (max-width: 991px)';
@screen767: ~'only screen and (max-width: 767px)';
@screen540: ~'only screen and (max-width: 540px)';
@screen375: ~'only screen and (max-width: 375px)';
@screen320: ~'only screen and (max-width: 320px)';
/*breakpoint mixin end here*/

:root {
  --first-font: 'Space Grotesk', sans-serif;
  --second-font: 'Michroma', sans-serif;
  --heading-h1: normal 400 50px/60px var(--second-font);
  --heading-h2: normal 400 40px/48px var(--second-font);
  --heading-h3: normal 400 32px/48px var(--second-font);
  --heading-h4: normal 400 24px/30px var(--second-font);
  --heading-h5: normal 400 22px/28px var(--second-font);
  --heading-h6: normal 500 18px/28px var(--first-font);

  --body-large-regular: normal 400 18px/28px var(--first-font);
  --body-large-medium: normal 500 18px/28px var(--first-font);
  --body-large-bold: normal 700 18px/28px var(--first-font);

  --body-default-regular: normal 400 16px/24px var(--first-font);
  --body-default-medium: normal 500 16px/24px var(--first-font);
  --body-default-bold: normal 700 16px/24px var(--first-font);

  --body-small-regular: normal 400 14px/22px var(--first-font);
  --body-small-medium: normal 500 14px/22px var(--first-font);
  --body-small-bold: normal 700 14px/22px var(--first-font);

  --body-meta-regular: normal 400 14px/22px var(--first-font);
  --body-meta-medium: normal 500 14px/22px var(--first-font);
  --body-meta-bold: normal 700 14px/22px var(--first-font);

  --body-bold-medium: normal 700 16px/24px var(--first-font);
  --body-bold-large: normal 700 18px/28px var(--first-font);

  --body-reg-medium: normal 400 16px/24px var(--first-font);
  --body-reg-medium-michroma: normal 400 16px/24px var(--second-font);

  --body-regular-base: normal 400 14px/20px var(--first-font);
  --body-medium-base: normal 500 14px/20px var(--first-font);

  --body-extra-large: normal 500 22px/26px var(--first-font);
  --body-medium-extra-large: normal 500 20px/30px var(--first-font);
}

// Typography variables
@heading-h1: var(--heading-h1);
@heading-h2: var(--heading-h2);
@heading-h3: var(--heading-h3);
@heading-h4: var(--heading-h4);
@heading-h5: var(--heading-h5);
@heading-h6: var(--heading-h6);

// body text and variables
@body-large-reg: var(--body-large-regular);
@body-large-med: var(--body-large-medium);
@body-large-lar: var(--body-large-bold);

@body-default-reg: var(--body-default-regular);
@body-default-med: var(--body-default-medium);
@body-default-lar: var(--body-default-bold);

@body-small-reg: var(--body-small-regular);
@body-small-med: var(--body-small-medium);
@body-small-lar: var(--body-small-bold);

@body-meta-reg: var(--body-meta-regular);
@body-meta-med: var(--body-meta-medium);
@body-meta-lar: var(--body-meta-bold);

@body-bold-med: var(--body-bold-medium);
@body-bold-lar: var(--body-bold-large);

@body-reg-medium: var(--body-reg-medium);
@body-reg-medium-michroma: var(--body-reg-medium-michroma);
@body-extra-large: var(--body-extra-large);

@body-regular-base: var(--body-regular-base);
@body-medium-base: var(--body-medium-base);
@body-med-extra-lar: var(--body-medium-extra-large);

// colorPicker variables
@emerald1: #99d5a2;
@emerald2: #66bf73;
@emerald-main: #33aa44;
@emerald-3: #268033;
@emerald4: #1a5522;
@primary-dark: #008440;
@primary-light: #28a864;

@grey-01: #e6e6e6;
@grey-02: #999999;
@grey-03: #777777;
@grey-04: #6d6d6d;
@grey-05: #555555;
@grey-06: #4a4a4a;
@grey-07: #333333;
@grey-08: #1c1c1c;
@grey-09: #111111;
@grey-10: #010101;
@grey-11: #d4d6d9;
@grey-btn: #cccccc;
@dashed-border: #6c6f74;

@accent-dark: #263a30;
@stroke-dark: #d2d2e1;
@stroke-light: #e4e6ea;
@text-dark: #3a3b3c;
@text-light: #6c6f74;
@background: #d2d1e1;
@background-1: #f7f1e5;
@background-2: #f2f4f7;
@background-3: #fefdf9;
@background-selected: #f4f6f8;
@background-slider-blue: #d9ebfe;
@border-radius-blue: #96c7fd;

@status-white: #ffffff;
@status-grey: #a8a7aa;
@status-black: #000000;
@status-red: #e64e2c;
@status-green: #3bb665;
@status-yellow: #daae15;
@status-blue: #035fc4;
@product-status-white: #ffffff;
@product-stroke-light: #e4e6ea;
@product-stroke-dark: #d2d2e1;
@product-text-dark: #514f55;
@product-background-2: #f1f1f1;
@black-color: #000000;
@stroke-light: #d2d2e1;
@status-light-gray: #768a9f;
@status-med-grey: #ccd6de;

//global style variables
@colorPrimary: #038fde;
@secondary-color: #fa8c15;
@secondary-btn: #263a30;
@text-color: #545454;
@text-dark: #514f55;
@text-color-secondary: #595959;
@heading-color: #535353;
@header-text-color: #262626;
@layout-header-background: #fefefe;
@layout-header-background-dark: #003365;
@body-background: #f5f5f5;
@nav-bar-bg: #fffffc;
@nav-bar-text-color: @text-color;
@blue: #85ceff;
@nav-dark-bg: #003366;
@nav-dark-text-color: #038fdd;
@menu-dark-bg: @nav-dark-bg;
@menu-dark-color: @nav-dark-text-color;
@menu-dark-highlight-color: @secondary-color;
@menu-dark-arrow-color: @nav-dark-text-color;
@hor-nav-text-color: #fffffd;

@border-radius-base: 4px;
@border-radius-sm: 4px;
@border-radius-lg: 10px;
@layout-header-background: #fff;
@layout-header-padding: 0 32px;
@card-actions-li-margin: 12px 24px;

@heading-1-size: clamp(25px, 4.6vw, 38px);
@heading-2-size: clamp(20px, 4vw, 30px);
@heading-3-size: clamp(18px, 3.8vw, 24px);
@heading-4-size: clamp(16px, 3vw, 20px);
@heading-5-size: clamp(14px, 2.5vw, 16px);

/*typography css end here*/

//*all variable list link are here*//
/**https://ant.design/theme-editor**/
